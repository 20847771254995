import { make } from 'vuex-pathify';
import DetectsCordova from '@/check-in/Mixins/DetectsCordova';
import { isObject } from 'lodash';
import get from 'lodash/get';
import Vue from 'vue';

let activeTemplateIdsFromLocalStorage = {};

if (!window.setDefaultSettings) {
    activeTemplateIdsFromLocalStorage = JSON.parse(localStorage.getItem('active-template-ids')) || {};
}

const moduleState = {
    connectedDevice: {
        id: null,
        name: null
    },
    devices: [],
    hasBluetooth: true,
    isConnected: false,
    isConnecting: false,
    isScanning: false,

    availablePrinters: [], // Electron only
    activeTemplateIds: activeTemplateIdsFromLocalStorage, // Keyed by user ID and then event ID
    defaultPrinter: null, // Electron only
    printOnFirstCheckin: false
};

const getters = {
    ...make.getters(moduleState),

    canPrint (state) {
        if (!DetectsCordova.computed.isCordova()) {
            return true;
        }

        return state.isConnected;
    },

    activeTemplateId (state, getters, rootState) {
        const userId = window.user.id;
        const eventId = rootState.Event.event.id;

        const activeTemplateId = get(state.activeTemplateIds, `${userId}.${eventId}`, null);

        if (activeTemplateId === null && rootState.Event.event?.badge_templates?.length > 0) {
            return rootState.Event.event.badge_templates[0].id;
        }

        return activeTemplateId;
    }
};

const mutations = {
    ...make.mutations(moduleState),

    activeTemplateId (state, templateId) {
        const userId = window.user.id;
        const eventId = this.state.Event.event.id;

        if (!isObject(state.activeTemplateIds)) {
            Vue.set(state, 'activeTemplateIds', {});
        }

        if (!isObject(state.activeTemplateIds[userId])) {
            Vue.set(state.activeTemplateIds, userId, {});
        }

        Vue.set(state.activeTemplateIds[userId], eventId, templateId);

        if (window.setDefaultSettings) {
            window.setDefaultSettings({
                defaultPrinter: state.defaultPrinter,
                activeTemplateIds: state.activeTemplateIds
            });
        } else {
            localStorage.setItem('active-template-ids', JSON.stringify(state.activeTemplateIds));
        }
    },

    defaultPrinter (state, printer) {
        state.defaultPrinter = printer;

        if (window.setDefaultSettings) {
            window.setDefaultSettings({
                defaultPrinter: printer,
                activeTemplateIds: state.activeTemplateIds
            });
        }
    }
};

const actions = {
    connect ({ commit, state }) {
        commit('isConnecting', true);

        window.cordovaBridge.message('printers.connect', state.connectedDevice);
    },

    disconnect ({ commit }) {
        commit('isConnected', false);
        commit('connectedDevice', { strModelName: null, strSerialNumber: null, strMACAddress: null });

        window.cordovaBridge.message('printers.disconnect');
    },

    scan ({ commit }) {
        commit('isScanning', true);

        window.cordovaBridge.message('printers.scan');
    },

    print (moduleState, base64EncodedImage) {
        if (!DetectsCordova.computed.isCordova()) {
            window.print();
            return;
        }

        window.cordovaBridge.message('printers.print', base64EncodedImage);
    },

    selectDevice ({ commit, dispatch }, device) {
        commit('connectedDevice', device);
        dispatch('connect');
    }
};

export default {
    namespaced: true,
    state: moduleState,
    mutations,
    actions,
    getters
};
