import Vue from 'vue';

const store = Vue.observable({
    initialized: false,
    isCordova: false
});

export default {
    mounted () {
        if (store.initialized) {
            return;
        }

        store.initialized = true;
        store.isCordova = document.body.classList.contains('cordova');

        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                store.isCordova = mutation.target.classList.contains('cordova');
            });
        });

        observer.observe(document.body, {
            attributes: true,
            attributeFilter: ['class']
        });
    },

    computed: {
        isCordova () {
            return store.initialized && store.isCordova;
        }
    }
};
