<template>
    <div>
        <button
            class="button-text button-info disabled:opacity-50 disabled:cursor-not-allowed text-left"
            :disabled="confirmedGuestsCount === 0"
            @click="exportZip"
        >
            Bulk export check-in QR codes
            <app-icon
                v-tippy
                name="info-circle"
                class="h-4 w-4 ml-2 text-gray-600"
                :content="`Export a (.zip) file containing QR code image files for your ${confirmedGuestsCount} confirmed guests. Useful for badges and sharing with guests outside of RSVPify.`"
                stroke
            ></app-icon>
        </button>

        <export-button-modal
            v-model="isModalVisible"
            :state="state"
            :download-url="downloadUrl"
            @closed="isModalVisible = false"
        ></export-button-modal>
    </div>
</template>

<script>
import { get } from 'vuex-pathify';
import Export from '@/mixins/Export';
import InteractsWithAbly from '@/mixins/InteractsWithAbly';
import axios from '@/util/axios';

const STATE_EXPORTING = 'exporting';
const STATE_EXPORT_READY = 'export-ready';
const STATE_FAILED = 'failed';

export default {
    mixins: [Export, InteractsWithAbly],

    props: {
        confirmedGuestsCount: {
            type: Number,
            required: true
        }
    },

    computed: {
        event: get('Event/event'),

        zendeskTicketUrl () {
            return window.urls.ticket;
        }
    },

    methods: {
        exportZip () {
            this.listenForEvents();

            this.isModalVisible = true;
            this.state = STATE_EXPORTING;

            this.initiateExport();
        },

        initiateExport () {
            axios.get(this.route('check-in.events.check-in-qrcodes.export', {
                event: this.event
            }));
        },

        listenForEvents () {
            this.$echo.private(`events.${this.event.id}.check-in-qrcodes`)
                .listen('.Domain\\CheckIn\\Events\\CheckinQrcodesExportFailed', () => {
                    this.state = STATE_FAILED;
                })
                .listen('.Domain\\CheckIn\\Events\\CheckInQrcodesExported', ({ downloadUrl }) => {
                    this.downloadUrl = downloadUrl;
                    this.state = STATE_EXPORT_READY;
                    window.location = downloadUrl;
                });
        }
    }
};
</script>
