/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'print-text': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M5.25 17.249h-3a1.5 1.5 0 01-1.5-1.5v-7.5a1.5 1.5 0 011.5-1.5h19.5a1.5 1.5 0 011.5 1.5v7.5a1.5 1.5 0 01-1.5 1.5h-3M3.75 9.749h1.5" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/><path pid="1" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M5.25 12.749h13.5v10.5H5.25zM18.75 6.749H5.25v-4.5a1.5 1.5 0 011.5-1.5h10.5a1.5 1.5 0 011.5 1.5zM8.25 15.749h7.5M8.25 18.749h5.25"/>'
  }
})
