<template>
    <div>
        <div class="flex items-center space-x-2">
            <button
                class="button-text button-info disabled:opacity-50 disabled:cursor-not-allowed"
                :disabled="confirmedGuestsCount === 0"
                @click="isModalOpen = true"
            >
                Export PDF of badges

                <app-icon
                    v-tippy
                    name="info-circle"
                    class="h-4 w-4 ml-2 text-gray-600"
                    content="Generate a PDF with badges for all attendees, ready for pre-printing."
                    stroke
                ></app-icon>
            </button>

            <beta-icon></beta-icon>
        </div>

        <export-badges-modal v-model="isModalOpen"></export-badges-modal>
    </div>
</template>

<script>
import { get } from 'vuex-pathify';
import ExportBadgesModal from '@/components/core/ExportBadgesModal.vue';

export default {
    components: { ExportBadgesModal },

    props: {
        confirmedGuestsCount: {
            type: Number,
            required: true
        }
    },

    data () {
        return {
            isModalOpen: false
        };
    },

    computed: {
        event: get('Event/event')
    }
};
</script>
