/* eslint-disable */
require('./add-circle-stroke')
require('./add-circle-thick-stroke')
require('./add-circle')
require('./add-square-stroke')
require('./add-tab-stroke')
require('./add')
require('./alarm-bell-off-stroke')
require('./alarm-bell-stroke')
require('./alert-circle-stroke')
require('./alert-diamond-stroke')
require('./alert-finger-stroke')
require('./alert-laptop-stroke')
require('./alert-triangle-stroke')
require('./alert-triangle')
require('./anchor-stroke')
require('./angle-brackets-stroke')
require('./animal-bird-circle-stroke')
require('./animal-bird-stroke')
require('./animal-cat-head-stroke')
require('./animal-cat-stroke')
require('./animal-chicken-stroke')
require('./animal-dog-circle-stroke')
require('./animal-dog-forbidden-stroke')
require('./animal-dog-head-stroke')
require('./animal-dog-leash-stroke')
require('./animal-dog-stroke')
require('./animal-fish-bowl-stroke')
require('./animal-paw-stroke')
require('./app-link-stroke')
require('./app-link')
require('./app-page-account-stroke')
require('./app-page-text')
require('./army-badge-stroke')
require('./arrange-letter-stroke')
require('./arrow-down-chevron-square-stroke')
require('./arrow-down-chevron-stroke')
require('./arrow-down-chevron')
require('./arrow-down-circle')
require('./arrow-down-left-thick-stroke')
require('./arrow-down-thick-stroke')
require('./arrow-expand-stroke')
require('./arrow-left-chevron-stroke')
require('./arrow-left-chevron')
require('./arrow-left-circle-stroke')
require('./arrow-left-circle')
require('./arrow-left-thick-stroke')
require('./arrow-right-chevron-stroke')
require('./arrow-right-chevron')
require('./arrow-right-circle')
require('./arrow-right-thick-stroke')
require('./arrow-right')
require('./arrow-shrink-stroke')
require('./arrow-shuffle')
require('./arrow-up-chevron-stroke')
require('./arrow-up-chevron')
require('./arrow-up-circle-stroke')
require('./arrow-up-circle')
require('./arrow-up-down-chevron')
require('./arrow-up-thick-stroke')
require('./attachment-alt-stroke')
require('./attachment-stroke')
require('./award-badge-stroke')
require('./award-medal-first-stroke')
require('./award-medal-stroke')
require('./award-ribbon-alt-stroke')
require('./award-ribbon-first-stroke')
require('./award-ribbon-stroke')
require('./award-trophy-stroke')
require('./baby-care-bottle-stroke')
require('./baby-care-clothes-stroke')
require('./baby-care-pacifier-stroke')
require('./baby-care-pacifier')
require('./baby-care-stroller-stroke')
require('./badge-stroke')
require('./baggage-plane-stroke')
require('./barbecue-grill-stroke')
require('./barbecue-grill')
require('./beach-palm-stroke')
require('./beach-palm-water-stroke')
require('./beach-sun-birds-stroke')
require('./board-game-deuce-stroke')
require('./board-game-dice-pawn-stroke')
require('./board-game-dice-stroke')
require('./book-closed-bookmark-stroke')
require('./book-closed-heart-stroke')
require('./book-open-bookmark-stroke')
require('./bookmark-check-stroke')
require('./bookmark-star-stroke')
require('./bookmark-stroke')
require('./bowling-set-stroke')
require('./bread-baguette')
require('./bread-slice-stroke')
require('./bread-slice')
require('./bread-wheat-stroke')
require('./breakfast-cereal-bowl-stroke')
require('./breakfast-croissant-stroke')
require('./breakfast-croissant')
require('./breakfast-english-stroke')
require('./breakfast-english')
require('./buildings-cloudy-stroke')
require('./buildings-modern-stroke')
require('./buildings-modern')
require('./buildings-official-stroke')
require('./buildings-outdoors-stroke')
require('./buildings-stroke')
require('./button-stop-stroke')
require('./calendar-add-circle-stroke')
require('./calendar-add-stroke')
require('./calendar-add')
require('./calendar-alt-stroke')
require('./calendar-check-circle-stroke')
require('./calendar-check-circle')
require('./calendar-check-stroke')
require('./calendar-clock-stroke')
require('./calendar-clock')
require('./calendar-disable-stroke')
require('./calendar-download-stroke')
require('./calendar-edit')
require('./calendar-forbidden-stroke')
require('./calendar-forbidden')
require('./calendar-heart-stroke')
require('./calendar-info-stroke')
require('./calendar-plane-stroke')
require('./calendar-setting')
require('./calendar-share-stroke')
require('./calendar-star-stroke')
require('./calendar-stroke')
require('./calendar')
require('./camera-stroke')
require('./camping-fire-marshmallows-stroke')
require('./camping-tent-forest-stroke')
require('./camping-tent-stroke')
require('./camping-trees-fire-stroke')
require('./cards-spade-diamond-stroke')
require('./cards-stroke')
require('./casino-clover-lucky-stroke')
require('./casino-dealer-man-stroke')
require('./casino-dealer-woman-stroke')
require('./casino-stroke')
require('./chair-stroke')
require('./chair')
require('./chart-analytics-stroke')
require('./chart-stroke')
require('./chat-translate-stroke')
require('./chat-translate')
require('./check-circle-alt-stroke')
require('./check-circle-filled')
require('./check-circle-stroke')
require('./check-circle')
require('./check-square-alt-stroke')
require('./check-square-stroke')
require('./check')
require('./chess-figures-stroke')
require('./chess-knight-stroke')
require('./chess-pawn-stroke')
require('./chess-rook-stroke')
require('./clock-timezone-stroke')
require('./close-circle-stroke')
require('./close-circle')
require('./close-square-stroke')
require('./close-stroke')
require('./close')
require('./clothing-accessory-beanie-winter-1-stroke')
require('./clothing-accessory-beanie-winter-2-stroke')
require('./clothing-accessory-bowtie-stroke')
require('./clothing-accessory-hat-cap-1-stroke')
require('./clothing-accessory-hat-cap-2-stroke')
require('./clothing-accessory-hat-cap-3-stroke')
require('./clothing-accessory-tie-stroke')
require('./clothing-blazer-jacket-stroke')
require('./clothing-dress-stroke')
require('./clothing-hoodie-stroke')
require('./clothing-pants-stroke')
require('./clothing-polo-shirt-stroke')
require('./clothing-shirt-plain-stroke')
require('./clothing-shorts-stroke')
require('./clothing-sweater-stroke')
require('./clothing-tank-top-stroke')
require('./clothing-tshirt-stroke')
require('./color-brush-stroke')
require('./color-painting-palette-stroke')
require('./color-painting-palette')
require('./color-palette-sample-stroke')
require('./community-chat')
require('./compass-stroke')
require('./concert-beach-stroke')
require('./concert-microphone-stroke')
require('./concert-sing-stroke')
require('./conversation-chat-bubble-stroke')
require('./conversation-chat-stroke')
require('./conversation-chat-text-stroke')
require('./covid-carrier-blood-1-stroke')
require('./covid-carrier-blood-2-stroke')
require('./covid-carrier-human-stroke')
require('./covid-carrier-packages-stroke')
require('./covid-graph-cured-decreasing-stroke')
require('./covid-graph-cured-increasing-stroke')
require('./covid-graph-cured-stable-stroke')
require('./covid-graph-death-rate-decreasing-stroke')
require('./covid-graph-death-rate-increasing-stroke')
require('./covid-graph-death-rate-stable-stroke')
require('./covid-graph-document-infected-report-stroke')
require('./covid-graph-infected-decreasing-stroke')
require('./covid-graph-infected-increasing-stroke')
require('./covid-graph-infected-stable-stroke')
require('./covid-hygiene-clean-bottle-shield-stroke')
require('./covid-hygiene-clean-bottle-virus-block-stroke')
require('./covid-hygiene-clean-bottle-virus-stroke')
require('./covid-hygiene-hand-liquid-soap-stroke')
require('./covid-hygiene-hand-sanitizer-liquid-1-stroke')
require('./covid-hygiene-hand-sanitizer-liquid-2-stroke')
require('./covid-hygiene-hand-sanitizer-liquid-3-stroke')
require('./covid-hygiene-hand-sanitizer-liquid-drop-stroke')
require('./covid-hygiene-hand-sanitizer-liquid-virus-block-stroke')
require('./covid-hygiene-hand-sanitizer-spray-stroke')
require('./covid-hygiene-hand-sanitizer-spray-virus-block-stroke')
require('./covid-hygiene-hand-sanitizer-virus-block-stroke')
require('./covid-hygiene-hand-sanitizer-virus-shield-stroke')
require('./covid-hygiene-hand-soap-1-stroke')
require('./covid-hygiene-hand-soap-2-stroke')
require('./covid-hygiene-hand-wash-stroke')
require('./covid-hygiene-hand-wipe-paper-1-stroke')
require('./covid-hygiene-hand-wipe-paper-2-stroke')
require('./covid-infrared-thermometer-gun-stroke')
require('./covid-mutation-1-stroke')
require('./covid-mutation-2-stroke')
require('./covid-mutation-stronger-stroke')
require('./covid-mutation-temperature-change-stroke')
require('./covid-protection-face-mask-1-stroke')
require('./covid-protection-face-mask-2-stroke')
require('./covid-protection-face-mask-3-stroke')
require('./covid-protection-face-shield-1-stroke')
require('./covid-protection-face-shield-2-stroke')
require('./covid-protection-sanitizer-spray-stroke')
require('./covid-protection-wash-hands-stroke')
require('./covid-quarantine-14-days-stroke')
require('./covid-quarantine-bed-stroke')
require('./covid-quarantine-calendar-1-stroke')
require('./covid-quarantine-calendar-14-days-stroke')
require('./covid-quarantine-calendar-2-stroke')
require('./covid-quarantine-hospital-stroke')
require('./covid-quarantine-house-1-stroke')
require('./covid-quarantine-house-2-stroke')
require('./covid-quarantine-house-shield-stroke')
require('./covid-quarantine-self-lockdown-1-stroke')
require('./covid-quarantine-self-lockdown-2-stroke')
require('./covid-social-distancing-1-stroke')
require('./covid-social-distancing-2-stroke')
require('./covid-social-distancing-attention-stroke')
require('./covid-social-distancing-correct-1-stroke')
require('./covid-social-distancing-correct-2-stroke')
require('./covid-social-distancing-correct-3-stroke')
require('./covid-social-distancing-correct-4-stroke')
require('./covid-social-distancing-correct-5-stroke')
require('./covid-social-distancing-correct-6-stroke')
require('./covid-social-distancing-forbidden-close-1-stroke')
require('./covid-social-distancing-forbidden-close-2-stroke')
require('./covid-social-distancing-forbidden-close-3-stroke')
require('./covid-social-distancing-forbidden-close-4-stroke')
require('./covid-social-distancing-forbidden-touch-stroke')
require('./covid-social-distancing-man-stroke')
require('./covid-social-distancing-not-allowed-space-man-stroke')
require('./covid-social-distancing-not-allowed-space-woman-stroke')
require('./covid-social-distancing-protect-shield-1-stroke')
require('./covid-social-distancing-protect-shield-2-stroke')
require('./covid-social-distancing-virus-stroke')
require('./covid-social-distancing-woman-stroke')
require('./covid-symptoms-cold-fever-stroke')
require('./covid-symptoms-fever-stroke')
require('./covid-symptoms-nausea-stroke')
require('./covid-symptoms-virus-diarrhea-stroke')
require('./covid-symptoms-virus-headache-1-stroke')
require('./covid-symptoms-virus-headache-2-stroke')
require('./covid-symptoms-virus-loss-smell-1-stroke')
require('./covid-symptoms-virus-loss-smell-2-stroke')
require('./covid-symptoms-virus-lung-damage-stroke')
require('./covid-symptoms-virus-stomach-stroke')
require('./covid-transmission-virus-airplane-stroke')
require('./covid-transmission-virus-briefcase-stroke')
require('./covid-transmission-virus-cough-stroke')
require('./covid-transmission-virus-crowd-stroke')
require('./covid-transmission-virus-expand-stroke')
require('./covid-transmission-virus-float-wind-stroke')
require('./covid-transmission-virus-handshake-stroke')
require('./covid-transmission-virus-human-infected-stroke')
require('./covid-transmission-virus-human-transmit-1-stroke')
require('./covid-transmission-virus-human-transmit-2-stroke')
require('./covid-transmission-virus-increase-rate-stroke')
require('./covid-transmission-virus-inhale-stroke')
require('./covid-transmission-virus-mobile-application-stroke')
require('./covid-transmission-virus-touch-finger-stroke')
require('./covid-transmission-virus-touch-hand-1-stroke')
require('./covid-transmission-virus-touch-hand-2-stroke')
require('./covid-transmission-virus-transmit-stroke')
require('./covid-transmission-virus-transportation-stroke')
require('./covid-transmission-virus-visible-stroke')
require('./covid-transmission-virus-wind-breath-stroke')
require('./covid-vaccine-people-shield-stroke')
require('./covid-vaccine-pill-stroke')
require('./covid-vaccine-shield-stroke')
require('./covid-vaccine-syringe-stroke')
require('./covid-vaccine-virus-stroke')
require('./covid-virus-1-stroke')
require('./covid-virus-2-stroke')
require('./covid-virus-3-stroke')
require('./covid-virus-4-stroke')
require('./covid-virus-heal-1-stroke')
require('./covid-virus-heal-2-stroke')
require('./covid-virus-lab-research-magnifier-alt-stroke')
require('./covid-virus-lab-research-magnifier-stroke')
require('./covid-virus-lab-research-microscope-stroke')
require('./covid-virus-lab-research-pill-stroke')
require('./covid-virus-lab-research-syringe-stroke')
require('./covid-virus-lab-research-test-tube-stroke')
require('./covid-virus-lifelong-1-stroke')
require('./covid-virus-lifelong-2-stroke')
require('./covid-virus-pandemic-1-stroke')
require('./covid-virus-pandemic-2-stroke')
require('./covid-virus-pandemic-3-stroke')
require('./covid-virus-patient-1-stroke')
require('./covid-virus-patient-2-stroke')
require('./covid-virus-reinfected-stroke')
require('./covid-virus-warning-1-stroke')
require('./covid-virus-warning-2-stroke')
require('./covid-virus-warning-3-stroke')
require('./credit-card-amex')
require('./credit-card-default')
require('./credit-card-discover')
require('./credit-card-dollar')
require('./credit-card-mastercard')
require('./credit-card-share-stroke')
require('./credit-card-stroke')
require('./credit-card-visa')
require('./credit-card')
require('./credit-cards-stacked')
require('./currency-dollar-circle')
require('./cursor-double-click-stroke')
require('./dashboard-stroke')
require('./dashboard')
require('./day-sunrise-stroke')
require('./day-sunset-stroke')
require('./design-tool-layout')
require('./dessert-cake-birthday-stroke')
require('./dessert-cake-birthday')
require('./dessert-cake-cherry-stroke')
require('./dessert-cake-cherry')
require('./dessert-cookie-stroke')
require('./dessert-cupcake-stroke')
require('./dessert-cupcake')
require('./dessert-ice-cream-bowl-stroke')
require('./dessert-ice-cream-bowl')
require('./dessert-ice-cream-cone-stroke')
require('./dessert-ice-cream-cone-swirl-stroke')
require('./dessert-ice-cream-cone-swirl')
require('./dessert-ice-cream-cone')
require('./dessert-ice-cream-popsicle-alt-stroke')
require('./dessert-ice-cream-popsicle-alt')
require('./dessert-ice-cream-popsicle-stroke')
require('./dessert-ice-cream-popsicle')
require('./dessert-pie-stroke')
require('./dessert-pie')
require('./discount-bubble-stroke')
require('./download-bottom-stroke')
require('./download-circle-stroke')
require('./download-circle')
require('./download-cloud-stroke')
require('./download-cloud')
require('./drink-beer-glass-foam-stroke')
require('./drink-beer-glass-foam')
require('./drink-beer-glass-stroke')
require('./drink-beer-glass')
require('./drink-beer-half-glass-stroke')
require('./drink-beer-half-glass')
require('./drink-beer-mug-stroke')
require('./drink-beer-mug')
require('./drink-can-stroke')
require('./drink-champagne-bottle-glass-stroke')
require('./drink-champagne-bottle-stroke')
require('./drink-champagne-bottle')
require('./drink-champagne-cheers-stroke')
require('./drink-champagne-cheers')
require('./drink-champagne-glass-stroke')
require('./drink-cocktail-coconut-stroke')
require('./drink-cocktail-coconut')
require('./drink-cocktail-glass-alt-stroke')
require('./drink-cocktail-glass-stroke')
require('./drink-cocktail-glass')
require('./drink-cocktail-martini-stroke')
require('./drink-coffee-cold-stroke')
require('./drink-coffee-mug-stroke')
require('./drink-coffee-travel-stroke')
require('./drink-glass-stroke')
require('./drink-spirits-glass')
require('./drink-tea-kettle-stroke')
require('./drink-wine-bottle-glass-stroke')
require('./drink-wine-glass-stroke')
require('./duplicate-circle-filled')
require('./duplicate-circle-stroke')
require('./duplicate-circle')
require('./duplicate-square-filled')
require('./duplicate-square-stroke')
require('./duplicate-square')
require('./edit-stroke')
require('./edit')
require('./email-action-settings-stroke')
require('./email-check-stroke')
require('./email-clock-stroke')
require('./email-close-stroke')
require('./email-envelope-circle-stroke')
require('./email-envelope-stroke')
require('./email-envelope')
require('./email-forbidden-stroke')
require('./email-forbidden')
require('./email-forward-stroke')
require('./email-search-stroke')
require('./email-send-envelope-stroke')
require('./email-send-envelope')
require('./email-send-stroke')
require('./email-sync-stroke')
require('./email-warning-stroke')
require('./email')
require('./family-add-member-stroke')
require('./family-child-stroke')
require('./family-couple-man-man-stroke')
require('./family-couple-man-woman-stroke')
require('./family-couple-woman-woman-stroke')
require('./family-father-child-stroke')
require('./family-frame-stroke')
require('./family-home-stroke')
require('./family-mother-child-stroke')
require('./file-add-circle-stroke')
require('./file-bookmark-stroke')
require('./file-copy-stroke')
require('./file-copy')
require('./file-edit-stroke')
require('./file-task-list-stroke')
require('./file-text-image')
require('./filter-stroke')
require('./filter')
require('./fitness-weights-stroke')
require('./flag-check')
require('./flag-small-stroke')
require('./flag-stroke')
require('./flags-stroke')
require('./font-picker-stroke')
require('./food-allergy-gluten-free-stroke')
require('./food-allergy-wheat-free-stroke')
require('./food-burger-meal-stroke')
require('./food-burger-stroke')
require('./food-candy')
require('./food-cheese-stroke')
require('./food-cheese')
require('./food-donut')
require('./food-drumstick-stroke')
require('./food-drumstick')
require('./food-eating-set-stroke')
require('./food-egg')
require('./food-eggs')
require('./food-fork-knife-stroke')
require('./food-fork-spoon')
require('./food-french-fries-stroke')
require('./food-gingerbread-man')
require('./food-hot-dog-stroke')
require('./food-pasta-bowl-warm-stroke')
require('./food-pasta-bowl-warm')
require('./food-pasta-plate-warm-stroke')
require('./food-pizza-stroke')
require('./food-rice-stroke')
require('./food-room-service-alt-stroke')
require('./food-room-service-stroke')
require('./food-steak-shrimp')
require('./food-taco-stroke')
require('./food-truck-stroke')
require('./food-turkey')
require('./fruit-apple-stroke')
require('./fruit-apple')
require('./fruit-banana-stroke')
require('./fruit-banana')
require('./fruit-cherry-stroke')
require('./fruit-cherry')
require('./fruit-grapes-stroke')
require('./fruit-grapes')
require('./fruit-orange-stroke')
require('./fruit-orange')
require('./fruit-pear-stroke')
require('./fruit-pear')
require('./fruit-strawberry-stroke')
require('./fruit-strawberry')
require('./fruit-watermelon-stroke')
require('./fruit-watermelon')
require('./gift-share-stroke')
require('./gift-stroke')
require('./gift-tag-stroke')
require('./gift')
require('./globe-model-stroke')
require('./globe-stroke')
require('./globe-web-stroke')
require('./grid-download-stroke')
require('./grid-lock-stroke')
require('./grid-lock')
require('./grid-warning-stroke')
require('./heart-circle-stroke')
require('./heart-gift-stroke')
require('./heart-stroke')
require('./help-buoy')
require('./help-chat-alt-stroke')
require('./help-chat-stroke')
require('./help-circle-filled')
require('./help-circle-stroke')
require('./holiday-christmas-invitation-snowflake-stroke')
require('./holiday-christmas-invitation-stroke')
require('./holiday-christmas-santa-stroke')
require('./holiday-christmas-snow-globe-stroke')
require('./holiday-christmas-snowflake-alt-stroke')
require('./holiday-christmas-snowflake-stroke')
require('./holiday-christmas-snowflake')
require('./holiday-christmas-tree-ornament-stroke')
require('./holiday-christmas-tree-ornaments-gift-stroke')
require('./holiday-christmas-tree-ornaments-stroke')
require('./holiday-christmas-tree-stroke')
require('./holiday-halloween-calendar-ghost-stroke')
require('./holiday-halloween-pumpkin-stroke')
require('./holiday-halloween-spider-web-stroke')
require('./holiday-july4-fireworks-rocket-stroke')
require('./holiday-july4-fireworks-stars-stroke')
require('./holiday-july4-fireworks-stick-stroke')
require('./holiday-july4-fireworks-stroke')
require('./hospital-bedroom-nurse-stroke')
require('./hotel-bedroom-stroke')
require('./hotel-bunk-bed-stroke')
require('./hotel-double-bed-stroke')
require('./house-alt-stroke')
require('./house-apartment-stroke')
require('./house-heart-stroke')
require('./house-nature-stroke')
require('./house-stroke')
require('./image-add-circle-stroke')
require('./image-landscape-stroke')
require('./image-upload-stroke')
require('./info-circle-stroke')
require('./info-circle')
require('./laptop-launch-stroke')
require('./laptop-launch')
require('./layout-three-columns-stroke')
require('./layout-three-columns')
require('./light-bulb-stroke')
require('./lighthouse-stroke')
require('./like-chat-stroke')
require('./like-circle-stroke')
require('./like-click-stroke')
require('./like-shine-stroke')
require('./link-alt-stroke')
require('./link-stroke')
require('./link')
require('./list-add')
require('./list-bullets')
require('./list-check-stroke')
require('./list-numbers')
require('./list-stroke')
require('./loader')
require('./loading-half')
require('./location-map-pin-2-stroke')
require('./location-map-pin-alt-stroke')
require('./location-map-pin-stroke')
require('./location-map-pin')
require('./location-parking-alt-stroke')
require('./location-parking-car-stroke')
require('./location-parking-stroke')
require('./location-pin-1-stroke')
require('./location-pin-airport-stroke')
require('./location-pin-alt-stroke')
require('./location-pin-bar-stroke')
require('./location-pin-camera-stroke')
require('./location-pin-cart-stroke')
require('./location-pin-church-stroke')
require('./location-pin-globe-stroke')
require('./location-pin-map-stroke')
require('./location-pin-secret-stroke')
require('./location-pin-star-stroke')
require('./location-pin-stroke')
require('./location-pin-target-stroke')
require('./location-road-trip-circle-stroke')
require('./location-road-trip-stroke')
require('./lock-stroke')
require('./lock')
require('./logout-stroke')
require('./mailbox-stroke')
require('./makeup-lipstick-stroke')
require('./makeup-mirror-stroke')
require('./makeup-nail-polish-stroke')
require('./medical-instrument-syringe-stroke')
require('./megaphone-alt-stroke')
require('./megaphone-announce')
require('./megaphone-stroke')
require('./messages-bubble-double-stroke')
require('./messages-user-alt-bubble-circle-stroke')
require('./messages-user-alt-bubble-square-stroke')
require('./messages-user-bubble-circle-stroke')
require('./messages-user-check-stroke')
require('./messages-user-warning-stroke')
require('./microphone-podcast-stroke')
require('./microphone-stroke')
require('./microphone')
require('./money-bag-dollar-stroke')
require('./money-bag-share-stroke')
require('./money-bags')
require('./money-basket-stroke')
require('./money-wallet-stroke')
require('./monitor-code-send-stroke')
require('./move-dots')
require('./move-hand-stroke')
require('./move-hand')
require('./move-vertical-arrows-stroke')
require('./move-vertical-arrows')
require('./music-clef-sheet-stroke')
require('./music-clef-stroke')
require('./music-note-1-stroke')
require('./music-note-2-stroke')
require('./music-note-3-stroke')
require('./music-speaker-note-stroke')
require('./music-speaker-stroke')
require('./navigation-menu-horizontal-stroke')
require('./navigation-menu-horizontal')
require('./navigation-menu-vertical-stroke')
require('./navigation-menu-vertical')
require('./navigation-menu')
require('./night-club-disco-ball-stroke')
require('./night-moon-stroke')
require('./notes-stack-stroke')
require('./office-business-card-stroke')
require('./open-quote')
require('./outdoors-landscape-meadow-stroke')
require('./outdoors-tree-road-stroke')
require('./outdoors-tree-valley-stroke')
require('./outdoors-water-sun-stroke')
require('./paragraph-align-center-stroke')
require('./paragraph-align-left-stroke')
require('./paragraph-align-right-stroke')
require('./party-balloon-stroke')
require('./party-balloons-stroke')
require('./party-cheers-stroke')
require('./party-concert-dj-stroke')
require('./party-concert-duet-stroke')
require('./party-confetti-stroke')
require('./party-confetti-whistle-stroke')
require('./party-dance-man-stroke')
require('./party-dance-stroke')
require('./party-dance-woman-stroke')
require('./party-decoration-stroke')
require('./party-decorations-stroke')
require('./party-hat-stroke')
require('./party-mask-stroke')
require('./payment-stripe-stroke')
require('./performance-magician-stroke')
require('./performance-microphone-stage-stroke')
require('./performance-movie-star-stroke')
require('./performance-theater-masks-stroke')
require('./performance-theater-stroke')
require('./plus-minus-stroke')
require('./power-button-stroke')
require('./power-button')
require('./print-text')
require('./printer')
require('./qr-code')
require('./question-circle-stroke')
require('./receipt-dollar-stroke')
require('./receipt-stroke')
require('./record')
require('./refresh-arrow-stroke')
require('./religion-astrology-book')
require('./religion-bible-stroke')
require('./religion-christianity-stroke')
require('./religion-church-stroke')
require('./religion-cross-stroke')
require('./religion-islam-stroke')
require('./religion-mitzvah-star-stroke')
require('./religion-peace-alt-stroke')
require('./religion-peace-stroke')
require('./remove-circle-stroke')
require('./remove-circle')
require('./remove-square-stroke')
require('./resize-expand')
require('./rocket-flying-stroke')
require('./rotate-back-stroke')
require('./rotate-back')
require('./rotate-forward')
require('./safety-exit-door-left')
require('./school-building-stroke')
require('./school-man-graduate-stroke')
require('./school-woman-graduate-stroke')
require('./science-molecule-stroke')
require('./seafood-crab-stroke')
require('./seafood-crab')
require('./seafood-fish-stroke')
require('./seafood-fish')
require('./seafood-lobster-stroke')
require('./seafood-lobster')
require('./seafood-salmon-stroke')
require('./seafood-shrimp-stroke')
require('./seafood-shrimp')
require('./search-house')
require('./search-stroke')
require('./search')
require('./season-autumn-stroke')
require('./season-spring-stroke')
require('./season-summer-stroke')
require('./season-winter-stroke')
require('./settings-cog-stroke')
require('./settings-cog')
require('./settings-cogs-stroke')
require('./settings-slider-stroke')
require('./settings-slider')
require('./settings-toggle')
require('./settings-vertical-stroke')
require('./share-button')
require('./share-stroke')
require('./shield-bolt')
require('./shoes-flip-flops-stroke')
require('./shoes-heels-stroke')
require('./shoes-sneakers-stroke')
require('./shopping-bag-dollar-stroke')
require('./shopping-basket-add-circle-stroke')
require('./shopping-basket-edit-stroke')
require('./shopping-basket-edit')
require('./shopping-basket-star-stroke')
require('./shopping-basket-stroke')
require('./shopping-cart-cloud-stroke')
require('./shopping-click-buy-stroke')
require('./shopping-discount-square-stroke')
require('./shopping-free-circle-stroke')
require('./shopping-money-case-share-stroke')
require('./shopping-pay-cash-stroke')
require('./shopping-pay-coin-stroke')
require('./shopping-pay-dollar-alt-stroke')
require('./shopping-pay-dollar-stroke')
require('./shopping-store-stroke')
require('./social-facebook-stroke')
require('./social-facebook')
require('./social-google-plus')
require('./social-instagram-stroke')
require('./social-instagram')
require('./social-linkedin')
require('./social-pinterest-stroke')
require('./social-pinterest')
require('./social-profile-bubble-stroke')
require('./social-twitter-stroke')
require('./social-twitter')
require('./spa-board')
require('./sports-baseball-bat-ball-stroke')
require('./sports-baseball-glove-stroke')
require('./sports-basketball-ball-stroke')
require('./sports-basketball-hoop-stroke')
require('./sports-fishing-line-stroke')
require('./sports-football-ball-stroke')
require('./sports-football-goal-stroke')
require('./sports-football-helmet-stroke')
require('./sports-golf-ball-stroke')
require('./sports-golf-hole-aim-stroke')
require('./sports-hiking-person-stroke')
require('./sports-ping-pong-paddle-stroke')
require('./sports-soccer-ball-stroke')
require('./sports-soccer-field-stroke')
require('./sports-soccer-goalkeeper-glove')
require('./sports-soccer-kick-ball')
require('./sports-soccer-player-ball')
require('./sports-soccer-player')
require('./sports-tennis-ball-stroke')
require('./sports-volleyball-ball-stroke')
require('./stamps-mail-stroke')
require('./star-add-circle-stroke')
require('./star-circle-stroke')
require('./star-share-stroke')
require('./star-shield-stroke')
require('./star-stroke')
require('./stop-sign-stroke')
require('./stop-sign')
require('./table-download-circle')
require('./table-restaurant-stroke')
require('./table-stroke')
require('./table-warning-stroke')
require('./tablet-stroke')
require('./tag-barcode-stroke')
require('./tag-dollar-circle-stroke')
require('./tag-dollar-stroke')
require('./tag-euro-stroke')
require('./tag-star-stroke')
require('./tag-stroke')
require('./tags-add')
require('./tags-check')
require('./tags-double')
require('./tags-minus')
require('./target-stroke')
require('./team-approve-disapprove-stroke')
require('./team-chat-stroke')
require('./team-idea-stroke')
require('./team-meeting-stroke')
require('./team-remote-stroke')
require('./team-stroke')
require('./technology-computer-stroke')
require('./technology-computer')
require('./technology-laptop-stroke')
require('./technology-mobile-hashtag-stroke')
require('./technology-mobile-launch-stroke')
require('./technology-mobile-phone-qr-code')
require('./technology-mobile-qr-code-stroke')
require('./technology-mobile-stroke')
require('./technology-mobile')
require('./technology-monitor-exchange-stroke')
require('./technology-qr-code-scan-stroke')
require('./technology-qr-code-scan')
require('./technology-tablet-touch-stroke')
require('./text-bold')
require('./text-input-area-stroke')
require('./text-italic')
require('./text-underline')
require('./tic-tac-toe-stroke')
require('./ticket-add-circle-stroke')
require('./ticket-add-circle')
require('./ticket-alt-stroke')
require('./ticket-basketball-stroke')
require('./ticket-check-stroke')
require('./ticket-check')
require('./ticket-classic-stroke')
require('./ticket-clock-stroke')
require('./ticket-concert-music-stroke')
require('./ticket-concert-stroke')
require('./ticket-day-1-stroke')
require('./ticket-day-1')
require('./ticket-day-30-stroke')
require('./ticket-day-30')
require('./ticket-day-7-stroke')
require('./ticket-day-7')
require('./ticket-discount-stroke')
require('./ticket-dollar-circle-stroke')
require('./ticket-dollar-circle')
require('./ticket-group-stroke')
require('./ticket-group')
require('./ticket-hold-stroke')
require('./ticket-movie-stroke')
require('./ticket-movie')
require('./ticket-music-stroke')
require('./ticket-music')
require('./ticket-remove-circle-stroke')
require('./ticket-remove')
require('./ticket-stroke')
require('./ticket')
require('./tickets-stroke')
require('./time-clock-stroke')
require('./time-hourglass-stroke')
require('./time-stopwatch-stroke')
require('./tool-wrench-stroke')
require('./toolbox-stroke')
require('./toolbox')
require('./toys-building')
require('./transportation-airplane-boarding-stroke')
require('./transportation-airplane-flying-stroke')
require('./transportation-airplane-stroke')
require('./transportation-bus-school-stroke')
require('./transportation-bus-stroke')
require('./transportation-sailboat-stroke')
require('./transportation-taxi-stroke')
require('./trash-stroke')
require('./trash')
require('./ui-webpage-bullets')
require('./umbrella-rain-stroke')
require('./umbrella-stroke')
require('./upload-button')
require('./upload-circle-stroke')
require('./upload-circle')
require('./upload-cloud-stroke')
require('./upload-cloud')
require('./user-add-circle-stroke')
require('./user-alt-add-circle-stroke')
require('./user-alt-search-stroke')
require('./user-badge-stroke')
require('./user-card-stroke')
require('./user-card')
require('./user-circle-stroke')
require('./user-circle')
require('./user-id-card-stroke')
require('./user-location-stroke')
require('./user-process-stroke')
require('./user-process')
require('./user-question-stroke')
require('./users-add-circle-stroke')
require('./users-alt-add-circle-stroke')
require('./users-alt-check-circle-stroke')
require('./users-check-circle-stroke')
require('./users-check-circle')
require('./users-key-stroke')
require('./users-location-stroke')
require('./users-location')
require('./users-stroke')
require('./users-upload-circle-stroke')
require('./users-upload-circle')
require('./users')
require('./vegetables-broccoli-stroke')
require('./vegetables-broccoli')
require('./vegetables-carrot-stroke')
require('./vegetables-carrot')
require('./vegetables-corn-stroke')
require('./vegetables-corn')
require('./vegetables-plate-stroke')
require('./vegetables-pumpkin-stroke')
require('./vegetables-pumpkin')
require('./vegetables-salad-stroke')
require('./vegetables-salad')
require('./video-circle-stroke')
require('./video-game-controller-stroke')
require('./video-game-controller-team-stroke')
require('./video-game-controller-wifi-stroke')
require('./video-game-magic-wand-stroke')
require('./video-game-question-box')
require('./video-player-stroke')
require('./video-stroke')
require('./video-webcam-stroke')
require('./view-stroke')
require('./view')
require('./vip-celebrity-stroke')
require('./vip-circle-stroke')
require('./vip-circle')
require('./vip-crown-circle-stroke')
require('./vip-crown-stroke')
require('./vip-diamond-hand-stroke')
require('./vip-diamond-stroke')
require('./vip-seating-stroke')
require('./vip-stroke')
require('./vip')
require('./wedding-altar-stroke')
require('./wedding-arch-stroke')
require('./wedding-bride-groom-stroke')
require('./wedding-cake-couple-stroke')
require('./wedding-cake-stroke')
require('./wedding-calendar-ring-stroke')
require('./wedding-car-stroke')
require('./wedding-celebration-stroke')
require('./wedding-certificate-stroke')
require('./wedding-gift-fund-stroke')
require('./wedding-ring-heart-stroke')
require('./wedding-ring-stroke')
require('./wedding-ring')
require('./wedding-rings-stroke')
require('./wine-glass-bottle')
require('./wine-glass')
require('./write-stroke')
require('./write')
