<template>
    <div>
        <div class="flex w-full justify-between">
            <form-field-wrapper>
                <input
                    v-model="form.title"
                    type="text"
                    name="name"
                    class="form-field max-w-xs"
                    placeholder="Give this mailing a name..."
                >
            </form-field-wrapper>

            <base-dropdown placement="bottom-end">
                <template slot="trigger" slot-scope="{ triggerFunction }">
                    <stateful-button
                        class="button pr-3"
                        :loading="loading"
                        @click="triggerFunction"
                    >
                        <div class="flex items-center">
                            <span>Actions</span>
                            <app-icon
                                name="navigation-menu-horizontal"
                                class="ml-3"
                                stroke
                            ></app-icon>
                        </div>
                    </stateful-button>
                </template>

                <div class="mt-2 bg-white rounded-md border shadow cursor-pointer text-sm overflow-hidden">
                    <button
                        v-if="isScheduled"
                        class="block w-full text-left px-4 py-2 hover:text-purple hover:bg-gray-100 transition duration-150 ease-in-out"
                        @click="unscheduleCampaign(campaign)"
                    >
                        {{ actionMenuItemLabels.unschedule }}
                    </button>

                    <button class="block w-full text-left px-4 py-2 hover:text-purple hover:bg-gray-100 transition duration-150 ease-in-out" @click="saveAsDraftOrScheduled">
                        {{ actionMenuItemLabels.save }}
                    </button>

                    <button
                        v-if="campaign"
                        class="block w-full text-left px-4 py-2 hover:text-purple hover:bg-gray-100 transition duration-150 ease-in-out"
                        @click="duplicateCampaign(event, campaign)"
                    >
                        {{ actionMenuItemLabels.duplicate }}
                    </button>

                    <button class="block w-full text-left px-4 py-2 hover:text-purple hover:bg-gray-100 transition duration-150 ease-in-out" @click="discardChanges">
                        {{ actionMenuItemLabels.discard }}
                    </button>
                </div>
            </base-dropdown>
        </div>

        <div v-if="form.errors.has('title')" class="alert alert-error alert-sm mt-2">
            <p class="w-full font-normal text-center">
                {{ form.errors.get('title') }}
            </p>
        </div>

        <div v-if="form.errors.has('settings.audience')" class="alert alert-error alert-sm mt-2">
            <p class="w-full font-normal text-center">
                {{ form.errors.get('settings.audience') }}
            </p>
        </div>

        <div v-if="form.errors.has('credits')" class="alert alert-error alert-sm mt-2">
            <p class="w-full font-normal text-center">
                {{ form.errors.get('credits') }}
            </p>
        </div>

        <accordion-list
            v-model="step"
            class="mt-4"
            container-class=""
        >
            <accordion-list-item
                item-id="compose"
                class="bg-white border rounded-md"
                :expand-collapse-icon="false"
            >
                <template #header="{ active }">
                    <div class="w-full flex items-center px-4 py-2" :class="getAccordionHeaderStyle(active)">
                        <div class="mr-4 w-7 h-7 flex items-center justify-center text-white rounded-full" :class="getNumberBadgeStyles(active)">1</div>
                        <div class="flex-1">Compose Email</div>
                        <button class="button button-primary button-sm">Done</button>
                    </div>
                </template>

                <compose-email
                    v-if="form"
                    slot="content"
                    :event="event"
                    :form="form"
                    :default-button-options="defaultButtonOptions"
                    :available-from-email-domains="availableFromEmailDomains"
                    :site-key="siteKey"
                    @logo-uploaded="url => form.settings.logo_url = url"
                ></compose-email>
            </accordion-list-item>

            <accordion-list-item
                item-id="recipients"
                class="mt-2 bg-white border rounded-md"
                :expand-collapse-icon="false"
            >
                <template #header="{ active }">
                    <div class="w-full flex items-center px-4 py-2" :class="getAccordionHeaderStyle(active)">
                        <div class="mr-4 w-7 h-7 flex items-center justify-center text-white rounded-full" :class="getNumberBadgeStyles(active)">2</div>
                        <div class="flex-1">Select Recipients</div>

                        <app-icon
                            v-if="!active"
                            name="arrow-right-chevron"
                            class="h-4 w-4 text-gray-500"
                            stroke
                        ></app-icon>

                        <app-icon
                            v-else
                            name="arrow-down-chevron"
                            class="h-4 w-4 text-gray-500"
                            stroke
                        ></app-icon>
                    </div>
                </template>

                <div slot="content">
                    <div class="p-4">
                        <p class="mb-4 text-sm text-gray-500 italic">
                            Counts don’t include guests and invitees without an associated email address.
                        </p>

                        <select-list v-model="form.settings.audience">
                            <select-option
                                id="all-invitees"
                                class="my-2"
                                :disabled="shouldDisableAudience('all-invitees')"
                                :tooltip="audienceTooltip('all-invitees')"
                            >
                                All invitees on Invite List ({{ audienceCounts['all-invitees'] | number }})

                                <img
                                    v-if="!audienceIsIncludedInPlan('all-invitees')"
                                    class="h-5 w-5 inline"
                                    :src="asset('images/account/rsvpify-premium.svg')"
                                >
                            </select-option>

                            <select-option
                                id="invitees-without-reply"
                                class="my-2"
                                :disabled="shouldDisableAudience('invitees-without-reply')"
                                :tooltip="audienceTooltip('invitees-without-reply')"
                            >
                                Invitees who haven't replied ({{ audienceCounts['invitees-without-reply'] | number }})

                                <img
                                    v-if="!audienceIsIncludedInPlan('invitees-without-reply')"
                                    class="h-5 w-5 inline"
                                    :src="asset('images/account/rsvpify-premium.svg')"
                                >
                            </select-option>

                            <select-option
                                id="attending"
                                class="my-2"
                                :disabled="shouldDisableAudience('attending')"
                                :tooltip="audienceTooltip('attending')"
                            >
                                Attending ({{ audienceCounts['attending'] | number }})

                                <img
                                    v-if="!audienceIsIncludedInPlan('attending')"
                                    class="h-5 w-5 inline"
                                    :src="asset('images/account/rsvpify-premium.svg')"
                                >
                            </select-option>

                            <select-option
                                id="maybe-attending"
                                class="my-2"
                                :disabled="shouldDisableAudience('maybe-attending')"
                                :tooltip="audienceTooltip('maybe-attending')"
                            >
                                Maybe attending ({{ audienceCounts['maybe-attending'] | number }})

                                <img
                                    v-if="!audienceIsIncludedInPlan('maybe-attending')"
                                    class="h-5 w-5 inline"
                                    :src="asset('images/account/rsvpify-premium.svg')"
                                >
                            </select-option>

                            <select-option
                                id="not-attending"
                                class="my-2"
                                :disabled="shouldDisableAudience('not-attending')"
                                :tooltip="audienceTooltip('not-attending')"
                            >
                                Not attending ({{ audienceCounts['not-attending'] | number }})

                                <img
                                    v-if="!audienceIsIncludedInPlan('not-attending')"
                                    class="h-5 w-5 inline"
                                    :src="asset('images/account/rsvpify-premium.svg')"
                                >
                            </select-option>

                            <select-option
                                id="all-respondents"
                                class="my-2"
                                :disabled="shouldDisableAudience('all-respondents')"
                                :tooltip="audienceTooltip('all-respondents')"
                            >
                                All respondents ({{ audienceCounts['all-respondents'] | number }})

                                <img
                                    v-if="!audienceIsIncludedInPlan('all-respondents')"
                                    class="h-5 w-5 inline"
                                    :src="asset('images/account/rsvpify-premium.svg')"
                                >
                            </select-option>

                            <select-option
                                id="custom"
                                class="my-2"
                                :disabled="shouldDisableAudience('custom')"
                                :tooltip="audienceTooltip('custom')"
                            >
                                <div class="flex items-center space-x-1">
                                    <div>
                                        Select individual recipients ({{ customAudience.length | number }})
                                    </div>

                                    <button
                                        v-if="customAudience.length"
                                        class="button-text button-primary ml-2"
                                        @click="clearCustomAudience"
                                    >
                                        Clear
                                    </button>

                                    <img
                                        v-if="!audienceIsIncludedInPlan('custom')"
                                        class="h-5 w-5 inline"
                                        :src="asset('images/account/rsvpify-premium.svg')"
                                    >
                                </div>
                            </select-option>

                            <select-option
                                id="predicates"
                                class="my-2"
                                :disabled="shouldDisableAudience('predicates')"
                                :tooltip="audienceTooltip('predicates')"
                            >
                                <div class="flex items-center">
                                    <div class="flex items-center">
                                        <div>
                                            Custom audience ({{ predicatesAudienceSize | number }})

                                            <img
                                                v-if="!audienceIsIncludedInPlan('predicates')"
                                                class="h-5 w-5 inline"
                                                :src="asset('images/account/rsvpify-premium.svg')"
                                            >
                                        </div>
                                    </div>
                                </div>
                            </select-option>
                        </select-list>
                    </div>

                    <div v-if="form.settings.audience === 'custom'" class="p-4 border-t">
                        <campaign-audience-builder
                            :key="form.settings.audience"
                            v-model="customAudience"
                            :mode="form.settings.audience"
                            :event="event"
                        ></campaign-audience-builder>
                    </div>

                    <div v-if="form.settings.audience === 'predicates'" class="p-4 border-t">
                        <campaign-audience-builder
                            :key="form.settings.audience"
                            :mode="form.settings.audience"
                            :event="event"
                            :initial-predicates="form.settings.predicates"
                            @predicates="form.settings.predicates = $event"
                            @audience-size="predicatesAudienceSize = $event"
                        ></campaign-audience-builder>
                    </div>
                </div>
            </accordion-list-item>

            <accordion-list-item
                item-id="send"
                class="mt-2 bg-white border rounded-md"
                :expand-collapse-icon="false"
            >
                <template #header="{ active }">
                    <div class="w-full flex items-center px-4 py-2" :class="getAccordionHeaderStyle(active)">
                        <div class="mr-4 w-7 h-7 flex items-center justify-center text-white rounded-full" :class="getNumberBadgeStyles(active)">3</div>
                        <div class="flex-1">Send now or schedule</div>

                        <app-icon
                            v-if="!active"
                            name="arrow-right-chevron"
                            class="h-4 w-4 text-gray-500"
                            stroke
                        ></app-icon>

                        <app-icon
                            v-else
                            name="arrow-down-chevron"
                            class="h-4 w-4 text-gray-500"
                            stroke
                        ></app-icon>
                    </div>
                </template>

                <div slot="content" class="p-4">
                    <div v-if="campaignSize > event.owner.remainingEmailCredits" class="alert alert-error">
                        <p>This email requires <span class="font-bold">{{ campaignSize | number }}</span> credit(s), and you have only <span class="font-bold">{{ event.owner.remainingEmailCredits | number }}</span> credit(s) remaining this period. Please consider upgrading your RSVPify plan to add additional email credits.</p>
                    </div>

                    <div v-if="!event.isPublished" class="alert alert-warning">
                        <p>Heads up! You haven’t published your event yet. You can still send this email, but guests won’t be able to access your event or form until you <a class="underline" :href="route('publish-invite.index', event)">publish</a>.</p>
                    </div>

                    <div v-if="!allowCampaignSendingAsACourtesyUpgrade" class="alert alert-error">
                        <div class="flex items-center w-full">
                            <p class="grow">
                                For verification purposes, please <a :href="route('settings.event.payments-and-coupons', event)" class="underline">connect</a> a Stripe account to RSVPify before sending emails. Your Stripe account must also have a connected bank account to freely send emails from RSVPify.
                            </p>

                            <a
                                role="button"
                                target="_blank"
                                class="button button-primary shrink"
                                :href="route('settings.event.payments-and-coupons', event)"
                            >Connect a stripe account</a>
                        </div>
                    </div>

                    <div v-if="errorMessage" class="alert alert-error">
                        <p>{{ errorMessage }}</p>
                    </div>

                    <form-field-wrapper>
                        <select-list v-model="form.action">
                            <select-option id="send" class="my-2">Send now</select-option>
                            <select-option id="schedule" class="my-2">Schedule to auto-send later</select-option>
                        </select-list>
                    </form-field-wrapper>

                    <div v-if="form.action === 'schedule'" class="w-3/4 flex">
                        <div class="flex-1 mr-4">
                            <form-field-wrapper
                                :error="form.errors.get('scheduled_at.timestamp')"
                                :should-show-error="form.errors.has('scheduled_at.timestamp')"
                            >
                                <date-time-picker
                                    v-model="form.scheduled_at.timestamp"
                                    :min-datetime="minDateTime"
                                    placeholder="Select Send Time"
                                    title="Send Time"
                                    has-time
                                ></date-time-picker>
                            </form-field-wrapper>
                        </div>

                        <div class="flex-1">
                            <form-field-wrapper
                                :error="form.errors.get('scheduled_at.timezone_id')"
                                :should-show-error="form.errors.has('scheduled_at.timezone_id')"
                            >
                                <timezone-picker v-model="form.scheduled_at.timezone_id" :guess-timezone="shouldGuessTimezone"></timezone-picker>
                            </form-field-wrapper>
                        </div>
                    </div>

                    <div class="mt-4 flex items-center">
                        <div v-tippy="{ allowHTML: true, interactive: true, maxWidth: 500 }" :content="submitButtonTooltip">
                            <stateful-button
                                :disabled="!canSend"
                                :loading="loading"
                                class="button button-primary"
                                @click="submit"
                                v-html="submitButtonText"
                            ></stateful-button>
                        </div>

                        <stateful-button
                            class="button mx-4"
                            :loading="loading"
                            @click="saveAsDraft"
                        >
                            Save as Draft
                        </stateful-button>

                        <div class="text-gray-500">
                            {{ campaignSize | number }} <span
                                v-tippy
                                class="tooltip-text"
                                :content="`Sending this email requires ${numberFilter(campaignSize)} credits. You have ${numberFilter(event.owner.remainingEmailCredits)} credits remaining this period. Email credits are included in your selected plan and reset monthly. Need more credits? Chat with us!`"
                            >Email Credits</span>
                        </div>
                    </div>

                    <div v-if="form.errors.any()" class="alert alert-error alert-sm mt-2">
                        <p class="w-full font-normal text-center">Email NOT sent or scheduled. One or more required fields are missing. Please review the previous steps and fill in all required fields.</p>
                    </div>
                </div>
            </accordion-list-item>
        </accordion-list>
    </div>
</template>

<script>
import { get } from 'vuex-pathify';
import tinycolor from 'tinycolor2';
import { without, get as getValue, cloneDeep } from 'lodash';
import { asset } from '@codinglabs/laravel-asset';
import { DateTime } from 'luxon';
import DuplicateCampaign from '@/mixins/DuplicateCampaign';
import Form from '@/validation/Form';
import axios from '@/util/axios';
import numberFilter from '@/filters/NumberFilter';

export default {
    name: 'CampaignEditor',

    mixins: [DuplicateCampaign],

    props: {
        audienceCounts: {
            required: true,
            type: Object
        },

        availableFromEmailDomains: {
            type: Array,
            required: true
        },

        defaultButtonOptions: {
            type: Object,
            default: () => { return {}; }
        },

        defaultContent: {
            type: String,
            default: ''
        },

        defaultSubject: {
            type: String,
            default: ''
        },

        event: {
            required: true,
            type: Object
        },

        initialCampaign: {
            type: Object,
            default: null
        },

        scheduledAt: {
            type: Object,
            default: () => { return { timestamp: null, timezone_id: null }; }
        },

        siteKey: {
            type: String,
            required: true
        }
    },

    data () {
        return {
            autoSaveTimer: null,
            form: null,
            campaign: cloneDeep(this.initialCampaign),
            customAudience: [],
            hasUnsavedProgress: false,
            initialized: false,
            predicatesAudienceSize: 0,
            step: 'compose',
            loading: false,
            errorMessage: null
        };
    },

    computed: {
        actionMenuItemLabels () {
            return {
                unschedule: 'Unschedule and move to Drafts',
                duplicate: this.isScheduled ? 'Duplicate & save in Drafts' : 'Make a duplicate',
                discard: this.isScheduled ? 'Discard changes and send as scheduled' : 'Discard changes and exit',
                save: this.isScheduled ? 'Save changes & send as scheduled' : 'Save as draft and exit'
            };
        },

        allAudiences () {
            return [
                'all-invitees', 'invitees-without-reply', 'attending', 'maybe-attending',
                'not-attending', 'all-respondents', 'custom', 'predicates'
            ];
        },

        allowCampaignSendingAsACourtesyUpgrade () {
            return this.event.isOwnerOldEnoughToSendCampaigns
                || this.event.canSendNonPreviewCampaignsBeforeConnectingCardToPaymentProcessor
                || this.event.isStripeConnectionCapableForCardPayments;
        },

        availableAudiences () {
            return this.allAudiences.filter((audience) => {
                return this.isAudienceAvailable(audience);
            });
        },

        campaignSize () {
            if (!this.form.settings.audience) {
                return 0;
            }

            if (this.form.settings.audience === 'custom') {
                return this.customAudience.length;
            }

            if (this.form.settings.audience === 'predicates') {
                return this.predicatesAudienceSize;
            }

            return this.audienceCounts[this.form.settings.audience];
        },

        campaignListingRoutes () {
            return {
                draft: 'events.campaigns.drafts.index',
                schedule: 'events.campaigns.scheduled.index',
                send: 'events.campaigns.sent.index'
            };
        },

        canSend () {
            if (
                !this.hasVerifiedEmail
                || !this.isAccountVerified
                || !this.selectedScheduleOrSendAction
                || !this.campaignSize
                || !this.allowCampaignSendingAsACourtesyUpgrade
                || this.event.owner.hasEmailInReviewQueue
            ) {
                return false;
            }

            return this.event.owner.remainingEmailCredits >= this.campaignSize;
        },

        columns () {
            return [
                {
                    label: 'Title', property: 'title', desktop: true, sortable: true
                },
                {
                    label: 'First Name', property: 'first_name', desktop: true, sortable: true
                },
                {
                    label: 'Last Name', property: 'last_name', desktop: true, sortable: true
                },
                {
                    label: 'Email', property: 'email', desktop: true, sortable: true
                },
                {
                    label: 'Reply', property: 'reply', desktop: true, sortable: true
                }
            ];
        },

        formData () {
            if (this.form) {
                return this.form.data();
            }

            return null;
        },

        hasVerifiedEmail () {
            return !!this.event.owner.email_verified_at;
        },

        isAccountVerified () {
            return this.event.owner.is_verified == null || this.event.owner.is_verified === true;
        },

        isDraft () {
            if (!this.campaign) {
                return true;
            }

            if (this.campaign.shortState === 'Draft') {
                return true;
            }

            return false;
        },

        isScheduled () {
            return this.campaign && this.campaign.shortState === 'Scheduled';
        },

        ...get('Event/event@', {
            plan: 'plan'
        }),

        minDateTime () {
            return DateTime.local().setZone(this.timezoneName).setZone('UTC', { keepLocalTime: true }).toISO();
        },

        rows () {
            return [
                [
                    {
                        id: 1, title: 'Captain', first_name: 'Ruby', last_name: 'Montoya', email: 'ruby.montoya@navy.gov', reply: 'Attending'
                    },
                    {
                        id: 2, title: 'Mr.', first_name: 'Kyle', last_name: 'Montoya', email: '', reply: 'Attending'
                    },
                    {
                        id: 3, title: 'Ms.', first_name: 'Kira', last_name: 'Montoya', email: '', reply: 'Not Attending'
                    }
                ],
                [
                    {
                        id: 4, title: 'Mr.', first_name: 'Ashton', last_name: 'Fawler', email: 'ashton.fawler@gmail.com', reply: 'VIP 6 PM Admission'
                    }
                ],
                [
                    {
                        id: 5, title: 'Ms.', first_name: 'Tina', last_name: 'Austin', email: 'tina.austin@gmail.com', reply: 'VIP 6 PM Admission'
                    }
                ]
            ];
        },

        shouldGuessTimezone () {
            return !this.campaign && this.form.scheduled_at.timezone_id == null;
        },

        submitButtonText () {
            return this.form.action === 'schedule'
                ? `Schedule to send to <strong class="px-1">${numberFilter(this.campaignSize)}</strong> recipients`
                : `Send to <strong class="px-1">${numberFilter(this.campaignSize)}</strong> recipients`;
        },

        submitButtonTooltip () {
            if (this.event.owner.hasEmailInReviewQueue) {
                return 'You already have an email in the queue. Once this email is sent, you can send additional campaigns.';
            }

            if (!this.hasVerifiedEmail) {
                return `Please confirm your email address before sending any emails.
                    <div><a class="underline" href="${this.route('account.resend-email-verification')}">Resend your confirmation.</a></div>`;
            }

            if (!this.isAccountVerified) {
                return `Please <a class="underline" href="${window.urls.ticket}" target="_blank">contact</a> RSVPify’s passionate support team to enable sending on your account.`;
            }

            return null;
        },

        selectedScheduleOrSendAction () {
            return this.form.action === 'send' || this.form.action === 'schedule';
        },

        timezoneName () {
            if (!this.form.scheduled_at.timezone_id) {
                return '';
            }

            return window.timezones.find(({ id }) => {
                return id === this.form.scheduled_at.timezone_id;
            }).name;
        }
    },

    watch: {
        formData: {
            deep: true,
            handler () {
                // Detect initialization and skip auto-save until finished
                if (!this.initialized) {
                    return;
                }

                this.hasUnsavedProgress = true;

                if (this.autoSaveTimer) {
                    return;
                }

                if (!this.isDraft) {
                    return;
                }

                this.autoSaveTimer = setTimeout(() => {
                    this.autoSave();
                }, 30 * 1000);
            }
        },

        'form.action': function (newVal) {
            if (newVal === 'send') {
                this.$set(this.form, 'scheduled_at', { timestamp: null, timezone_id: null });
            } else if (newVal === 'schedule' && this.form.scheduled_at.timezone_id == null) {
                this.$set(this.form.scheduled_at, 'timezone_id', this.event.timezone_id);
            }
        },

        'form.settings.audience': function (newValue) {
            if (newValue !== 'predicates') {
                this.form.settings.predicates = [];
                this.predicatesAudienceSize = 0;
            }
        },

        'form.settings.logo_width': function (newValue) {
            if (newValue > 200) {
                this.form.settings.logo_width = 200;
            }
        }
    },

    created () {
        // window.addEventListener('beforeunload', (e) => {
        //     if (!this.hasUnsavedProgress) {
        //         return;
        //     }
        //
        //     if (!this.isDraft && this.campaign.shortState !== 'Scheduled') {
        //         return;
        //     }
        //
        //     e.preventDefault();
        //     e.returnValue = '';
        // });

        this.initializeForm();
        this.initializeCustomAudience();

        // If we're not editing an existing campaign, default to the first
        // available audience
        if (!this.initialCampaign) {
            this.form.settings.audience = this.availableAudiences[0];
        }

        // Wait until the data in the component is ready before enabling the
        // "formData" watcher. By doing that, unnecessary auto-save attempts
        // during component initialization are blocked.
        this.$nextTick(() => {
            this.initialized = true;
        });
    },

    methods: {
        audienceIsIncludedInPlan (audience) {
            return this.plan.features.CampaignAudience.types.includes(audience);
        },

        audienceTooltip (audience) {
            if (this.audienceIsIncludedInPlan(audience)) {
                return null;
            }

            return 'Please upgrade to send emails to this recipient audience.';
        },

        autoSave () {
            this.newSaveRequest('draft')
                .then(({ data }) => {
                    // If this campaign is saved for the first time, update the
                    // URL so that a page refresh shows the same page the user
                    // was seeing prior to the refresh.
                    if (!this.campaign) {
                        const editRoute = this.route('events.campaigns.edit', {
                            event: this.event,
                            campaign: data.data.id
                        });

                        window.history.replaceState(null, '', editRoute);
                    }

                    this.form.errors.clear();
                    this.campaign = data.data;
                });
        },

        clearCustomAudience () {
            this.$set(this, 'customAudience', []);
        },

        getAccordionHeaderStyle (active) {
            return {
                'border-b': active
            };
        },

        getNumberBadgeStyles (active) {
            return {
                'bg-purple-light': active,
                'bg-gray-500': !active
            };
        },

        initializeCustomAudience () {
            if (!this.initialCampaign || this.initialCampaign.settings.audience !== 'custom') {
                return;
            }

            this.customAudience = this.initialCampaign.messages.map((message) => {
                return {
                    id: message.sendable_id,
                    type: message.sendable_type
                };
            });
        },

        initializeForm () {
            let data = {};

            const emailParts = this.initialCampaign
                ? getValue(this.initialCampaign, 'from_email', 'invitations@rsvpify.org').split('@')
                : getValue(this.event.settings, 'confirmationEmails.fromEmail', 'invitations@rsvpify.org').replace('rsvpify.com', 'rsvpify.org').split('@');

            if (this.initialCampaign) {
                data = {
                    ...this.initialCampaign,
                    action: this.initialCampaign.scheduled_at ? 'schedule' : 'send',
                    scheduled_at: this.scheduledAt,
                    messages: null,
                    from_email_local_part: emailParts[0],
                    from_email_domain: emailParts[1]
                };
            } else {
                const emailBodyBackgroundColor = tinycolor(this.event.form.settings.theme.colors.background).toHexString();
                const accentBarColor = tinycolor(this.event.form.settings.theme.colors.accent).toHexString();

                data = {
                    action: 'send',
                    title: '',
                    body: this.defaultContent,
                    subject: this.defaultSubject,
                    from_name: 'RSVPify',
                    from_email_local_part: emailParts[0],
                    from_email_domain: emailParts[1],
                    from_email: `${emailParts[0]}@${emailParts[1]}`,
                    reply_to: window.user.email,
                    settings: {
                        logo: 'existing',
                        logo_url: null,
                        logo_width: 200,
                        backgroundColor: '#F2F2F2',
                        emailBodyBackgroundColor,
                        accentBarColor,
                        accentBarColorEnabled: true,
                        footerColors: { text: '#888888', link: '#555555' },
                        audience: null,
                        predicates: []
                    },
                    scheduled_at: this.scheduledAt
                };
            }

            this.form = new Form(data);
        },

        isAudienceAvailable (audience) {
            if (!this.audienceIsIncludedInPlan(audience)) {
                return false;
            }

            // The "custom" and "predicates" options require user input, which
            // means the audience count is always zero. That's why both options
            // are always available.
            if (audience === 'custom' || audience === 'predicates') {
                return true;
            }

            return this.audienceCounts[audience] > 0;
        },

        numberFilter,

        saveAsDraft () {
            this.save('draft');
        },

        saveAsDraftOrScheduled () {
            if (this.isScheduled) {
                this.save();
            } else {
                this.saveAsDraft();
            }
        },

        shouldDisableAudience (audience) {
            return !this.availableAudiences.includes(audience);
        },

        unscheduleCampaign (campaign) {
            axios.put(this.route('api.events.campaigns.unschedule', [this.event, campaign]))
                .then(() => {
                    this.$toasted.global.success('Campaign was unscheduled successfully.');
                    this.form.action = 'draft';
                    this.redirectToCampaignListing();
                }).catch(() => {
                    this.$toasted.global.error('There was an error unscheduling this campaign.');
                });
        },

        discardChanges () {
            App.alert().confirm(
                'Discard changes?',
                'Are you sure you want to discard the changes you\'ve made to this campaign?',
                {},
                () => {
                    this.redirectToCampaignListing();
                }
            );
        },

        newSaveRequest (action) {
            this.hasUnsavedProgress = false;

            if (this.autoSaveTimer) {
                clearTimeout(this.autoSaveTimer);
                this.autoSaveTimer = null;
            }

            const additionalData = {
                action,
                customAudience: this.customAudience
            };

            const fields = without(this.form.fields(), 'action', 'event');

            return this.campaign
                ? this.form.put(this.route('api.events.campaigns.update', [this.event, this.campaign]), fields, additionalData)
                : this.form.post(this.route('api.events.campaigns.store', this.event), fields, additionalData);
        },

        redirectToCampaignListing (action) {
            window.location.href = this.route(this.campaignListingRoutes[action || this.form.action], this.event);
        },

        save (action = null) {
            this.loading = true;
            this.errorMessage = null;

            this.newSaveRequest(action || this.form.action).then(async () => {
                if (action !== 'draft' && this.auth().user().plan.isFree) {
                    await App.alert().success(
                        '',
                        'Your email is in the queue.'
                    );
                }

                this.redirectToCampaignListing(action || this.form.action);
            }).catch((error) => {
                const { data } = error.response;
                this.errorMessage = data.message;
            }).finally(() => {
                this.loading = false;
                this.form.action = this.form.scheduled_at.timestamp ? 'schedule' : 'send';
            });
        },

        submitScheduledEmail () {
            const scheduledTime = DateTime.fromISO(this.form.scheduled_at.timestamp).toUTC().toFormat(`LLLL d, yyyy 'at' h:mm a`);

            App.alert().confirm(
                'Schedule Email?',
                null,
                {
                    confirmButtonText: 'SCHEDULE',
                    html: `RSVPify will send this email to a total of <strong>${numberFilter(this.campaignSize)}</strong> recipient(s) on ${scheduledTime} (${this.timezoneName}).`,
                    imageUrl: asset('/images/campaigns/send-email.svg'),
                    type: null
                },
                () => {
                    this.save();
                }
            );
        },

        submitImmediateDispatch () {
            App.alert().confirm(
                'Send Now?',
                null,
                {
                    confirmButtonText: 'OK',
                    html: `RSVPify will start sending this email to a total of <strong>${numberFilter(this.campaignSize)}</strong> recipient(s) right away.`,
                    imageUrl: asset('/images/campaigns/send-email.svg'),
                    type: null
                },
                () => {
                    this.save();
                }
            );
        },

        submit () {
            this.loading = true;
            this.errorMessage = null;

            this.validateFields().then(() => {
                if (this.form.action === 'schedule') {
                    this.submitScheduledEmail();
                } else {
                    this.submitImmediateDispatch();
                }
            }).finally(() => {
                this.loading = false;
            });
        },

        validateFields () {
            const additionalData = { customAudience: this.customAudience };

            return this.form.validate(this.route('api.events.campaigns.validate-store', this.event), null, additionalData);
        }
    }
};
</script>
