<template>
    <component
        :is="modalComponent"
        :value="value"
        :z-index="1000"
        title="Badge export"
        @closed="$emit('input', false)"
    >
        <template #header>
            <div class="flex items-center space-x-4">
                <div>Badge export</div>
                <beta-icon></beta-icon>
            </div>
        </template>

        <template>
            <div class="flex flex-col space-y-4">
                <form-field-wrapper label="Badge template">
                    <div class="flex flex-row space-x-4 overflow-x-auto overflow-y-hidden">
                        <div
                            v-for="template in event.badge_templates"
                            :key="template.id"
                            class="cursor-pointer"
                            @click="selectedTemplate = template"
                        >
                            <div class="h-24 w-44 rounded-lg border-2 py-1 px-2 flex items-center justify-center" :class="{ 'border-teal': selectedTemplate && selectedTemplate.id === template.id }">
                                <p class="text-purple tracking-wide font-semibold text-center">
                                    {{ template.name }}
                                </p>
                            </div>
                        </div>
                        <div v-if="event.badge_templates.length === 0" class="text-left py-2">
                            No badge templates found.
                            <a
                                :href="route('check-in.settings', { event, tab: 'badging'} )"
                                target="_blank"
                                class="button-text button-info"
                            >Manage your badge templates in the Check-In Application.</a>
                        </div>
                    </div>
                </form-field-wrapper>

                <form-field-wrapper label="Paper Size">
                    <simple-picker
                        v-model="options.paperSize"
                        :items="paperSizes"
                        item-label="label"
                        item-value="value"
                        placeholder-empty-state="Select paper size"
                    ></simple-picker>
                </form-field-wrapper>

                <div class="flex space-x-2">
                    <div class="flex-1">
                        <form-field-wrapper label="Page padding (mm)">
                            <div class="max-w-40">
                                <input
                                    v-model.number="options.pagePadding"
                                    type="number"
                                    class="form-field p-1"
                                    min="1"
                                    max="15"
                                    step="1"
                                >
                            </div>
                        </form-field-wrapper>
                    </div>

                    <div class="flex-1">
                        <form-field-wrapper
                            label="Badge padding (mm)"
                        >

                            <div class="max-w-40">
                                <input
                                    v-model.number="options.badgePadding"
                                    type="number"
                                    class="form-field p-1"
                                    min="1"
                                    max="15"
                                    step="1"
                                >
                            </div>
                        </form-field-wrapper>
                    </div>
                </div>

                <div class="flex space-x-2">
                    <div class="flex-1">
                        <form-field-wrapper label="Show cutting lines">
                            <toggle-switch v-model="options.showCuttingLines" class="pb-4"></toggle-switch>
                        </form-field-wrapper>
                    </div>

                    <div class="flex-1">
                        <form-field-wrapper
                            v-if="options.showCuttingLines"
                            label="Cutting lines color"
                        >
                            <color-picker v-model="options.lineColor"></color-picker>
                        </form-field-wrapper>
                    </div>
                </div>
            </div>
        </template>

        <template #footer="{ close }">
            <stateful-button
                class="button-primary"
                :disabled="!selectedTemplate"
                :loading="isLoading"
                @click="exportBadges"
            >
                Export
            </stateful-button>

            <button class="button" @click="close">Cancel</button>
        </template>
    </component>
</template>

<script>
import { get } from '@node_modules/vuex-pathify';
import { PAPER_SIZES } from '@/check-in/Components/Settings/Badging/BadgeLayoutCalculator';
import keys from 'lodash/keys';
import axios from '@/util/axios';
import RenderBadgeTemplate, { resolveTagFromGuestResource } from '@/check-in/Components/Settings/Badging/BadgeTemplateRenderer';
import RequestPrint from '@/check-in/Components/Settings/Badging/RequestPrint';
import ElectronSettingsAccess from '@/check-in/Mixins/ElectronSettingsAccess';

export default {
    mixins: [ElectronSettingsAccess],

    props: {
        value: {
            type: Boolean,
            required: true
        },

        modalComponent: {
            type: String,
            default: 'app-modal'
        }
    },

    computed: {
        event: get('Event/event')
    },

    data () {
        return {
            allGuests: [],
            isLoading: false,
            processedGuests: 0,
            totalGuests: 0,

            paperSizes: keys(PAPER_SIZES).map((key) => {
                return {
                    value: key,
                    label: PAPER_SIZES[key].label
                };
            }),

            options: {
                paperSize: 'A4',
                showCuttingLines: true,
                lineColor: '#999',
                pagePadding: 10,
                badgePadding: 5
            },
            selectedTemplate: null
        };
    },

    methods: {
        async exportBadges () {
            this.allGuests = [];
            this.processedGuests = 0;

            const toast = this.$toasted.info(
                `<div class="text-center">Starting export of guests' badges.<br><br>Please do not close this tab.</div>`,
                {
                    duration: null,
                    position: 'bottom-center'
                }
            );

            const route = this.route('api.events.guests.index', {
                event: this.event,
                columns: ['firstName', 'formalName', 'tags', 'confirmationCode', 'submissionUuid']
            });

            try {
                this.isLoading = true;

                let totalPages = null;
                let currentPage = 1;

                while (totalPages === null || currentPage <= totalPages) {
                    // eslint-disable-next-line no-await-in-loop
                    const response = await axios.get(route, {
                        params: {
                            page: currentPage,
                            per_page: 50
                        }
                    });

                    // Set total pages on first response
                    if (totalPages === null) {
                        totalPages = response.data.last_page;

                        this.totalGuests = response.data.total;
                    }

                    currentPage += 1;

                    this.processedGuests += response.data.data.length;
                    this.updateProgress(toast);

                    this.allGuests.push(response.data.data);
                }

                this.allGuests = this.allGuests.flat();

                toast.text(`Successfully fetched all ${this.totalGuests} guests!`);
                toast.goAway(2500);

                this.preparePrint();
            } catch (error) {
                toast.text(`Error fetching guests: ${error.message}`);
            } finally {
                this.isLoading = false;
            }
        },

        preparePrint () {
            const badges = this.allGuests.map((guest) => {
                return RenderBadgeTemplate(
                    this.selectedTemplate,
                    this.event,
                    guest,
                    {
                        tagResolver: resolveTagFromGuestResource
                    }
                );
            });

            RequestPrint(
                this.defaultPrinter,
                badges,
                true,
                {
                    padding: this.options.badgePadding,
                    marginTop: this.options.pagePadding,
                    marginBottom: this.options.pagePadding,
                    marginLeft: this.options.pagePadding,
                    marginRight: this.options.pagePadding,
                    cuttingLineStyle: this.options.showCuttingLines ? `1px dashed ${this.options.lineColor}` : 'none',
                    paperSize: this.options.paperSize
                }
            );
        },

        updateProgress (toast) {
            this.$nextTick(() => {
                toast.text(`Processing guests: ${this.processedGuests} / ${this.totalGuests}`);
            });
        }
    }
};
</script>
