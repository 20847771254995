// Paper sizes in millimeters
export const PAPER_SIZES = {
    A4: { width: 210, height: 297, label: 'A4 - mm: 210 x 297 - in: 8.3 x 11.7' },
    A3: { width: 297, height: 420, label: 'A3 - mm: 297 x 420 - in: 11.7 x 16.5' },
    LETTER: { width: 215.9, height: 279.4, label: 'Letter - mm: 215.9 x 279.4 - in: 8.5 x 11' }
};

class BadgeLayoutCalculator {
    constructor (options = {}) {
        this.padding = options.padding || 5;
        this.marginTop = options.marginTop || 10;
        this.marginBottom = options.marginBottom || 10;
        this.marginLeft = options.marginLeft || 10;
        this.marginRight = options.marginRight || 10;
        this.borderWidth = options.borderWidth || 2;
        this.cuttingLineStyle = options.cuttingLineStyle || '1px dashed #999';
        this.paperSize = options.paperSize || 'A4';
    }

    calculateSinglePageLayout (paperDimensions) {
        // Account for borders in available space calculation
        const totalBorderWidth = this.borderWidth * 2;
        const availableWidth = paperDimensions.width - this.marginLeft - this.marginRight - totalBorderWidth;
        const availableHeight = paperDimensions.height - this.marginTop - this.marginBottom - totalBorderWidth;

        // Calculate how many badges can fit in each direction including padding
        const badgesPerRow = Math.floor((availableWidth + this.padding) / (this.badgeWidth + this.padding));
        const badgesPerColumn = Math.floor((availableHeight + this.padding) / (this.badgeHeight + this.padding));

        // Ensure at least one badge can fit
        if (badgesPerRow < 1 || badgesPerColumn < 1) {
            throw new Error('Badge size too large for selected paper size');
        }

        // Calculate actual space needed for the grid
        const totalGridWidth = (badgesPerRow * this.badgeWidth) + ((badgesPerRow - 1) * this.padding);
        const totalGridHeight = (badgesPerColumn * this.badgeHeight) + ((badgesPerColumn - 1) * this.padding);

        // Calculate starting position to center the grid, accounting for borders
        const startX = this.marginLeft + this.borderWidth + (availableWidth - totalGridWidth) / 2;
        const startY = this.marginTop + this.borderWidth + (availableHeight - totalGridHeight) / 2;

        return {
            badgesPerRow,
            badgesPerColumn,
            totalBadgesPerPage: badgesPerRow * badgesPerColumn,
            startX,
            startY,
            gridDimensions: {
                width: totalGridWidth,
                height: totalGridHeight
            }
        };
    }

    calculateMultiPageLayout (badgeWidth, badgeHeight, badgeHTMLs) {
        this.badgeWidth = badgeWidth;
        this.badgeHeight = badgeHeight;

        const paperDimensions = typeof this.paperSize === 'string'
            ? PAPER_SIZES[this.paperSize.toUpperCase()]
            : this.paperSize;

        if (!paperDimensions) {
            throw new Error('Invalid paper size');
        }

        const singlePageLayout = this.calculateSinglePageLayout(paperDimensions);
        const totalPages = Math.ceil(badgeHTMLs.length / singlePageLayout.totalBadgesPerPage);
        const pages = [];

        let badgeIndex = 0;
        for (let pageNum = 0; pageNum < totalPages; pageNum++) {
            const positions = [];
            const cuttingLines = [];

            // Calculate positions for current page
            for (let row = 0; row < singlePageLayout.badgesPerColumn; row++) {
                for (let col = 0; col < singlePageLayout.badgesPerRow; col++) {
                    if (badgeIndex < badgeHTMLs.length) {
                        const x = singlePageLayout.startX + (col * (this.badgeWidth + this.padding));
                        const y = singlePageLayout.startY + (row * (this.badgeHeight + this.padding));

                        positions.push({
                            x,
                            y,
                            width: this.badgeWidth,
                            height: this.badgeHeight,
                            html: badgeHTMLs[badgeIndex]
                        });

                        // Add vertical cutting lines (except for last column)
                        if (col < singlePageLayout.badgesPerRow - 1) {
                            const lineX = x + this.badgeWidth + (this.padding / 2);
                            cuttingLines.push({
                                type: 'vertical',
                                x: lineX,
                                y: y - (this.padding / 2),
                                length: this.badgeHeight + this.padding
                            });
                        }

                        // Add horizontal cutting lines (except for last row)
                        if (row < singlePageLayout.badgesPerColumn - 1) {
                            const lineY = y + this.badgeHeight + (this.padding / 2);
                            cuttingLines.push({
                                type: 'horizontal',
                                x: x - (this.padding / 2),
                                y: lineY,
                                length: this.badgeWidth + this.padding
                            });
                        }

                        badgeIndex++;
                    }
                }
            }

            pages.push({
                pageNumber: pageNum + 1,
                positions,
                cuttingLines,
                gridDimensions: singlePageLayout.gridDimensions,
                pageMetrics: {
                    marginTop: this.marginTop,
                    marginBottom: this.marginBottom,
                    marginLeft: this.marginLeft,
                    marginRight: this.marginRight,
                    padding: this.padding,
                    borderWidth: this.borderWidth,
                    pageWidth: paperDimensions.width,
                    pageHeight: paperDimensions.height
                }
            });
        }

        return {
            totalPages,
            badgesPerPage: singlePageLayout.totalBadgesPerPage,
            totalBadges: badgeHTMLs.length,
            pages
        };
    }

    generateMultiPageHTML (layout) {
        const commonStyles = `
      @page {
        size: ${layout.pages[0].pageMetrics.pageWidth}mm ${layout.pages[0].pageMetrics.pageHeight}mm;
        margin: 0;
      }
      body {
        margin: 0;
        padding: 0;
      }
      .page {
        position: relative;
        width: ${layout.pages[0].pageMetrics.pageWidth}mm;
        height: ${layout.pages[0].pageMetrics.pageHeight}mm;
        box-sizing: border-box;
        border: ${this.borderWidth}mm solid black;
        page-break-after: always;
      }
      .page:last-child {
        page-break-after: auto;
      }
      .badge {
        position: absolute;
        box-sizing: border-box;
        border: 1px solid #ccc;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .badge p {
        padding: 0;
        margin: 0;
      }
      .cutting-line {
        position: absolute;
        background: transparent;
        border: none;
      }
      .cutting-line.vertical {
        width: 0;
        border-left: ${this.cuttingLineStyle};
      }
      .cutting-line.horizontal {
        height: 0;
        border-top: ${this.cuttingLineStyle};
      }
    `;

        const pagesHTML = layout.pages.map((page) => {
            const badgesHTML = page.positions.map((pos) => {
                return `
        <div class="badge" style="
          left: ${pos.x}mm;
          top: ${pos.y}mm;
          width: ${pos.width}mm;
          height: ${pos.height}mm;
        ">
          ${pos.html}
        </div>
      `;
            }).join('');

            const cuttingLinesHTML = page.cuttingLines.map((line) => {
                return `
        <div class="cutting-line ${line.type}" style="
          left: ${line.x}mm;
          top: ${line.y}mm;
          ${line.type === 'vertical' ? 'height' : 'width'}: ${line.length}mm;
        "></div>
      `;
            }).join('');

            return `
        <div class="page">
          ${badgesHTML}
          ${cuttingLinesHTML}
        </div>
      `;
        }).join('\n');

        return `
      <!DOCTYPE html>
      <html>
        <head>
          <meta charset="utf-8">
          <style>${commonStyles}</style>
        </head>
        <body>
          ${pagesHTML}
        </body>
      </html>
    `;
    }
}

export default BadgeLayoutCalculator;
