import { sync } from 'vuex-pathify';

export default {
    computed: {
        ...sync('Printers/*'),

        isElectron () {
            return window.isElectronApp;
        }
    },

    methods: {
        refreshElectronData () {
            if (this.isElectron && window.requestPrinters) {
                window.requestPrinters();
            }
        }
    }
};
