import BadgeLayoutCalculator from '@/check-in/Components/Settings/Badging/BadgeLayoutCalculator';
import { PrintInNewTab } from '@/check-in/Components/Settings/Badging/PrintInNewTab';
import PrinterConfigs from '@/check-in/Components/Settings/Badging/PrinterConfigs';
import get from 'lodash/get';

const defaultPrinter = 'Brother_QL_820NWB';

export default function (deviceName, badgeHtmls, openNewTab = false, bulkOptions = false) {
    const selectedPrinter = deviceName !== null ? deviceName : defaultPrinter;

    const printerConfig = get(PrinterConfigs, selectedPrinter, PrinterConfigs[defaultPrinter]);

    let content = printerConfig.template.replace('INSERTBODY', badgeHtmls);

    if (bulkOptions) {
        const defaultBulkOptions = {
            padding: 5,
            marginTop: 10,
            marginBottom: 10,
            marginLeft: 10,
            marginRight: 10,
            borderWidth: 2,
            paperSize: 'A4'
        };

        const layoutCalculator = new BadgeLayoutCalculator({
            ...defaultBulkOptions,
            ...(bulkOptions === true ? {} : bulkOptions)
        });

        const layout = layoutCalculator.calculateMultiPageLayout(82, 62, badgeHtmls);

        content = layoutCalculator.generateMultiPageHTML(layout);
    }

    if (openNewTab && !window.isElectronApp) {
        PrintInNewTab(content);
    }

    document.dispatchEvent(new CustomEvent('print-request', {
        detail: {
            content,
            options: {
                deviceName,
                silent: true,
                printBackground: false,
                color: false,
                margin: {
                    marginType: 'custom',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0
                },
                landscape: false,
                pageSize: {
                    width: printerConfig.width,
                    height: printerConfig.height
                },
                scaleFactor: 100
            },
            debug: openNewTab
        }
    }));
}
