const config_ql820_nwb = {
    template: `
    <html>
      <head>
        <style>
           @page {
            size: 82mm 62mm;
            margin: 0;
            overflow: hidden;
            -webkit-box-shadow:inset 0px 0px 0px 2px #000;
          -moz-box-shadow:inset 0px 0px 0px 2px #000;
          box-shadow:inset 0px 0px 0px 2px #000;
          }

          @media print {
  @page {
     size: 82mm 62mm;
     margin: 0;
  }
}

          html {
              overflow: hidden;
          }

          body {
            margin: 0;
            padding: 0;
            width: 82mm;
            height: 62mm;
            font-family: Arial, sans-serif;
            position: relative;
            overflow: hidden;
            -webkit-box-shadow:inset 0px 0px 0px 2px #000;
            -moz-box-shadow:inset 0px 0px 0px 2px #000;
            box-shadow:inset 0px 0px 0px 2px #000;
          }

          p {
              margin: 0;
              padding: 0;
          }

          body > div {
            border: 1px solid black;
          }
        </style>
      </head>
      <body>
        INSERTBODY
      </body>
    </html>
  `,
    width: 62000,
    height: 82000
};

export default {
    Brother_QL_820NWB: config_ql820_nwb
};
