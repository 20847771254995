import Vue from 'vue';
import Vuex from 'vuex';
import pathify from './pathify';

import Wall from './modules/wall';
import Form from './modules/form';
import Event from './modules/event';
import Tags from './modules/tags';
import Theme from './modules/theme';
import Submission from './modules/submission';
import InviteList from './modules/invite-list';
import CreateEvent from './modules/create-event';
import Seating from './modules/seating';
import UsedFeatures from './modules/used-features';
import Printers from '../check-in/Store/Printers';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        Wall,
        Form,
        Event,
        InviteList,
        Tags,
        Theme,
        CreateEvent,
        Submission,
        Seating,
        UsedFeatures,
        Printers
    },

    plugins: [pathify.plugin]
});
