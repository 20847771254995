<template>
    <section class="panel">
        <page-header icon="email-send-stroke">
            <template #label>
                <div class="flex items-center space-x-4">
                    <span>Email Confirmations &amp; Reminders</span>

                    <feature-enabled feature="event-emails-flag">
                        <div
                            v-tippy
                            content="Disabled all automated emails."
                            class="flex items-center"
                        >
                            <toggle-switch v-model="form.settings.emails.enabled"></toggle-switch>
                        </div>
                    </feature-enabled>
                </div>
            </template>

            <a :href="route('settings.event', { event })" class="button-icon button-sm">
                <app-icon name="close"></app-icon>
            </a>
        </page-header>

        <item-modified-warning v-if="form.isModified()"></item-modified-warning>

        <div class="relative">
            <div v-if="!form.settings.emails.enabled" class="absolute inset-0 w-full h-full opacity-50 bg-white z-10"></div>

            <div class="row mb-8">
                <div class="col-12 md:col-6">
                    <div
                        v-tippy
                        class="flex w-full rounded-md md:border py-4 px-2 items-center"
                        content="When enabled, email confirmations will include guest responses to all form fields (e.g. meal preferences, custom questions, notes, etc.)."
                    >
                        <toggle-switch v-model="form.settings.confirmationEmails.includeFormResponses">
                            Include replies to custom fields in email confirmation
                        </toggle-switch>
                    </div>
                </div>

                <div class="col-12 md:col-6">
                    <div
                        v-tippy
                        class="flex w-full rounded-md md:border py-4 px-2 items-center"
                        content="Include a unique confirmation code for each registered guest or group."
                    >
                        <toggle-switch v-model="form.settings.confirmationEmails.includeConfirmationCode">
                            Include a confirmation code
                        </toggle-switch>
                    </div>
                </div>
            </div>

            <div class="row mb-8">
                <div class="col-12 md:col-6">
                    <div
                        v-tippy
                        class="flex w-full rounded-md md:border py-4 px-2 items-center"
                        content="You’ll be able to scan this code on guest’s mobile device or email printout to check them in at your event."
                    >
                        <toggle-switch v-model="form.settings.confirmationEmails.includeCheckInQrCode">
                            Include QR code for check-in
                        </toggle-switch>
                    </div>
                </div>
            </div>

            <form-field-wrapper
                label="Auto Reminders"
                class="w-full md:w-3/4"
                :error="form.errors.getByPath('reminder_definitions')"
                :tooltip="autoRemindersTooltip"
            >
                <div
                    v-tippy="{ followCursor: true }"
                    class="w-fit"
                    :content="noDatesTooltip"
                >
                    <div class="flex items-center">
                        <div class="mr-2" :class="eventDoesntHaveDates ? 'text-gray-300' : 'text-gray-700'">Send guests a reminder in advance of start</div>

                        <div
                            v-tippy
                            class="flex flex-row mr-3"
                            :content="resolveReminderTooltip(0)"
                        >
                            <toggle-switch
                                v-model="form.reminder_definitions[0].active"
                                class="mr-2"
                                :disabled="isReminderDisabled(0)"
                            ></toggle-switch>

                            <simple-picker
                                v-model="form.reminder_definitions[0].send_in"
                                :items="getReminderOptions(form.reminder_definitions[1].send_in)"
                                item-label="label"
                                item-value="value"
                                placeholder-empty-state="Select..."
                                :disabled="isReminderDisabled(0) || !form.reminder_definitions[0].active"
                            ></simple-picker>
                        </div>

                        <div
                            v-tippy
                            class="flex flex-row"
                            :content="resolveReminderTooltip(1)"
                        >
                            <toggle-switch
                                v-model="form.reminder_definitions[1].active"
                                class="mr-2"
                                :disabled="isReminderDisabled(1)"
                            ></toggle-switch>

                            <simple-picker
                                v-model="form.reminder_definitions[1].send_in"
                                :items="getReminderOptions(form.reminder_definitions[0].send_in)"
                                item-label="label"
                                item-value="value"
                                placeholder-empty-state="Select..."
                                :disabled="isReminderDisabled(1) || !form.reminder_definitions[1].active"
                            ></simple-picker>
                        </div>
                    </div>
                </div>
            </form-field-wrapper>

            <form-field-wrapper
                v-if="!isCustomFromNameHidden"
                class="w-full md:w-1/2"
                :should-show-error="form.errors.has('settings.confirmationEmails.fromName')"
                :error="form.errors.get('settings.confirmationEmails.fromName')"
            >
                <template #label>
                    <div class="flex items-center space-x-2">
                        <span
                            v-tippy
                            content="Guests will see this name in their email client as the email's sender."
                            class="tooltip-text"
                        >
                            From Name
                        </span>

                        <img
                            v-if="customFromNameTooltip"
                            v-tippy
                            class="w-5 h-5"
                            :src="asset('images/account/rsvpify-premium.svg')"
                            :content="customFromNameTooltip"
                        >
                    </div>
                </template>

                <input
                    v-model="form.settings.confirmationEmails.fromName"
                    class="form-field"
                    placeholder="RSVPify"
                    :disabled="isCustomFromNameDisabled"
                >
            </form-field-wrapper>

            <feature-enabled feature="email-builder-from-email" :passthrough="availableFromEmailDomains.length > 1">
                <form-field-wrapper
                    label="From email"
                    tooltip="Email confirmations will be sent from this email address."
                    class="w-full md:w-1/2"
                    :should-show-error="form.errors.has('settings.confirmationEmails.fromEmail')"
                    :error="form.errors.get('settings.confirmationEmails.fromEmail')"
                >
                    <div class="flex items-center w-full">
                        <input
                            v-model="form.fromEmail"
                            class="form-field"
                            placeholder="invitations"
                        >
                        <span class="shrink">@</span>
                        <simple-picker
                            v-model="form.fromEmailDomain"
                            class="w-128"
                            :items="availableFromEmailDomains"
                        ></simple-picker>
                    </div>
                </form-field-wrapper>
            </feature-enabled>

            <form-field-wrapper
                label="Reply-to email"
                tooltip="Guest's direct replies to email confirmations will be sent to this email address."
                class="w-full md:w-1/2"
                :should-show-error="form.errors.has('settings.confirmationEmails.replyToEmail')"
                :error="form.errors.get('settings.confirmationEmails.replyToEmail')"
            >
                <input
                    v-model="form.settings.confirmationEmails.replyToEmail"
                    class="form-field"
                    placeholder="warren.bradley@company.com"
                >
            </form-field-wrapper>

            <div class="my-8 space-y-4 lg:space-y-0 flex flex-col lg:flex-row">
                <div class="w-full lg:w-1/2 max-w-sm">
                    <form-field-wrapper
                        label="Logo"
                        tooltip="This logo will be included at the top of all email confirmations."
                    >
                        <div class="row">
                            <div class="col-12 md:col-6 flex-col items-start justify-center">
                                <select-list v-model="form.settings.confirmationEmails.logoToUse">
                                    <select-option
                                        :id="null"
                                        class="mt-2 mb-2"
                                    >
                                        No Logo
                                    </select-option>

                                    <select-option
                                        id="existing"
                                        tooltip="Use the logo you added in Setup > Form Builder."
                                        :disabled="!formLogo"
                                        @click.native="form.settings.confirmationEmails.logoToUse = 'existing'"
                                    >
                                        Use Existing Logo
                                    </select-option>

                                    <select-option
                                        id="new"
                                        class="mt-2"
                                        tooltip="This logo will only be used for email confirmations."
                                    >
                                        Add a New Logo
                                    </select-option>
                                </select-list>

                                <fancy-image-uploader
                                    v-if="useNewLogo"
                                    conversion="email"
                                    @select-image="url => $set(form.settings.confirmationEmails, 'logoUrl', url)"
                                >
                                    <template #recommendations>
                                        PNG and JPG files allowed. Max file size: 2 MB.<br>
                                        Logo Size Recommendation: Max 600 px wide.
                                    </template>

                                    <template #default="{ launch }">
                                        <button class="button-text button-info mt-4" @click="launch">
                                            <app-icon
                                                name="add-circle"
                                                class="h-6 w-6 mr-2"
                                                stroke
                                            ></app-icon>
                                            {{ imageUploadText }}
                                        </button>
                                    </template>
                                </fancy-image-uploader>
                            </div>
                        </div>
                    </form-field-wrapper>
                </div>

                <div class="w-full lg:w-1/6 max-w-xs">
                    <form-field-wrapper label="Color" tooltip="This color will be used in accent elements and text on your confirmation.">
                        <color-picker v-model="form.settings.emails.colors.accent"></color-picker>
                    </form-field-wrapper>
                </div>

                <div v-if="emailLogo" class="w-full lg:flex-1">
                    <img
                        class="w-72"
                        :src="emailLogo"
                        alt="Confirmation email logo"
                    >
                </div>
            </div>

            <accordion-list v-model="typeAccordion">
                <accordion-list-item class="mb-2 p-2 border rounded-md" item-id="attending">
                    <template #header="{ active }">
                        <div
                            v-tippy
                            content="Sent when a guest is attending your event."
                            class="w-full mr-2"
                            :class="{ 'pb-2 border-b': active }"
                        >Attending confirmation email</div>
                    </template>

                    <template #content>
                        <div class="p-4">
                            <form-field-wrapper class="w-full md:w-1/2" :error="form.errors.get('subjectLineTranslations.subject-confirmation-attending')">
                                <template #label>
                                    <div class="flex items-center justify-center space-x-2">
                                        <span>Email Subject Line</span>
                                        <a
                                            v-if="showManageMultiLanguageSubjectLinesLink"
                                            :href="route('settings.event.languages-and-translations', event)"
                                            class="button-text button-info normal-case"
                                        >
                                            Manage Multi-language Subject lines
                                        </a>
                                    </div>
                                </template>
                                <input v-model="form.subjectLineTranslations['subject-confirmation-attending']" class="form-field">
                            </form-field-wrapper>

                            <form-field-wrapper
                                label="Email Introduction"
                                tooltip="Add event details or additional information. Added text will be displayed above the receipt and guest confirmation details."
                                class="w-full md:w-1/2"
                            >
                                <div class="border p-2 min-h-xs" @click.self="$refs.attendingIntroduction.focus()">
                                    <tiptap-editor
                                        ref="attendingIntroduction"
                                        v-model="form.settings.confirmationEmails.attending.introduction"
                                        simple
                                        :allow-links="allowCustomHyperLinksInEmails"
                                    ></tiptap-editor>
                                </div>
                            </form-field-wrapper>

                            <form-field-wrapper
                                label="Email Conclusion"
                                tooltip="Add event details or additional information. Added text will be displayed below the receipt and guest confirmation details."
                                class="w-full md:w-1/2"
                            >
                                <div class="border p-2 min-h-xs" @click.self="$refs.attendingConclusion.focus()">
                                    <tiptap-editor
                                        ref="attendingConclusion"
                                        v-model="form.settings.confirmationEmails.attending.conclusion"
                                        simple
                                        :allow-links="allowCustomHyperLinksInEmails"
                                    ></tiptap-editor>
                                </div>
                            </form-field-wrapper>
                        </div>
                    </template>
                </accordion-list-item>

                <accordion-list-item class="mb-2 p-2 border rounded-md" item-id="not-attending">
                    <template #header="{ active }">
                        <div
                            v-tippy
                            content="Sent when a guest or all invitees in a group indicate they are unable to attend your event."
                            class="w-full mr-2"
                            :class="{ 'pb-2 border-b': active }"
                        >Not attending confirmation email</div>
                    </template>

                    <template #content>
                        <div class="p-4">
                            <form-field-wrapper class="w-full md:w-1/2" :error="form.errors.get('subjectLineTranslations.subject-confirmation-not-attending')">
                                <template #label>
                                    <div class="flex items-center justify-center space-x-2">
                                        <span>Email Subject Line</span>
                                        <a
                                            v-if="showManageMultiLanguageSubjectLinesLink"
                                            :href="route('settings.event.languages-and-translations', event)"
                                            class="button-text button-primary normal-case"
                                        >
                                            Manage Multi-language Subject lines
                                        </a>
                                    </div>
                                </template>
                                <input v-model="form.subjectLineTranslations['subject-confirmation-not-attending']" class="form-field">
                            </form-field-wrapper>

                            <form-field-wrapper
                                label="Email Introduction"
                                tooltip="Add event details or additional information. Added text will be displayed above the receipt and guest confirmation details."
                                class="w-full md:w-1/2"
                            >
                                <div class="border p-2 min-h-xs" @click.self="$refs.notAttendingIntroduction.focus()">
                                    <tiptap-editor
                                        ref="notAttendingIntroduction"
                                        v-model="form.settings.confirmationEmails.notAttending.introduction"
                                        simple
                                        :allow-links="allowCustomHyperLinksInEmails"
                                    ></tiptap-editor>
                                </div>
                            </form-field-wrapper>

                            <form-field-wrapper
                                label="Email Conclusion"
                                tooltip="Add event details or additional information. Added text will be displayed below the receipt and guest confirmation details."
                                class="w-full md:w-1/2"
                            >
                                <div class="border p-2 min-h-xs" @click.self="$refs.notAttendingConclusion.focus()">
                                    <tiptap-editor
                                        ref="notAttendingConclusion"
                                        v-model="form.settings.confirmationEmails.notAttending.conclusion"
                                        simple
                                        :allow-links="allowCustomHyperLinksInEmails"
                                    ></tiptap-editor>
                                </div>
                            </form-field-wrapper>
                        </div>
                    </template>
                </accordion-list-item>

                <accordion-list-item class="mb-2 p-2 border rounded-md" item-id="canceled">
                    <template #header="{ active }">
                        <div
                            v-tippy
                            content="Sent if a guest changes their attending RSVP to not attending or cancels a scheduled appointment."
                            class="w-full mr-2"
                            :class="{ 'pb-2 border-b': active }"
                        >Canceled confirmation email</div>
                    </template>

                    <template #content>
                        <div class="p-4">
                            <form-field-wrapper class="w-full md:w-1/2" :error="form.errors.get('subjectLineTranslations.subject-confirmation-canceled')">
                                <template #label>
                                    <div class="flex items-center justify-center space-x-2">
                                        <span>Email Subject Line</span>
                                        <a
                                            v-if="showManageMultiLanguageSubjectLinesLink"
                                            :href="route('settings.event.languages-and-translations', event)"
                                            class="button-text button-primary normal-case"
                                        >
                                            Manage Multi-language Subject lines
                                        </a>
                                    </div>
                                </template>
                                <input v-model="form.subjectLineTranslations['subject-confirmation-canceled']" class="form-field">
                            </form-field-wrapper>

                            <form-field-wrapper
                                label="Email Introduction"
                                tooltip="Add event details or additional information. Added text will be displayed above the receipt and guest confirmation details."
                                class="w-full md:w-1/2"
                            >
                                <div class="border p-2 min-h-xs" @click.self="$refs.canceledIntroduction.focus()">
                                    <tiptap-editor
                                        ref="canceledIntroduction"
                                        v-model="form.settings.confirmationEmails.canceled.introduction"
                                        simple
                                        :allow-links="allowCustomHyperLinksInEmails"
                                    ></tiptap-editor>
                                </div>
                            </form-field-wrapper>

                            <form-field-wrapper
                                label="Email Conclusion"
                                tooltip="Add event details or additional information. Added text will be displayed below the receipt and guest confirmation details."
                                class="w-full md:w-1/2"
                            >
                                <div class="border p-2 min-h-xs" @click.self="$refs.canceledConclusion.focus()">
                                    <tiptap-editor
                                        ref="canceledConclusion"
                                        v-model="form.settings.confirmationEmails.canceled.conclusion"
                                        simple
                                        :allow-links="allowCustomHyperLinksInEmails"
                                    ></tiptap-editor>
                                </div>
                            </form-field-wrapper>
                        </div>
                    </template>
                </accordion-list-item>

                <accordion-list-item
                    v-if="areRemindersEnabled"
                    class="mb-2 p-2 border rounded-md"
                    item-id="reminders"
                >
                    <template #header="{ active }">
                        <div class="w-full mr-2" :class="{ 'pb-2 border-b': active }">Reminder email</div>
                    </template>

                    <template #content>
                        <div class="p-4">
                            <form-field-wrapper class="w-full md:w-1/2" :error="form.errors.get('subjectLineTranslations.subject-reminders-lt-24hrs')">
                                <template #label>
                                    <div class="flex flex-col space-y-2">
                                        <div class="flex space-x-2">
                                            <span>Email Subject Line</span>
                                            <a
                                                v-if="showManageMultiLanguageSubjectLinesLink"
                                                :href="route('settings.event.languages-and-translations', event)"
                                                class="button-text button-primary normal-case"
                                            >
                                                Manage Multi-language Subject lines
                                            </a>
                                        </div>
                                        <div class="font-normal normal-case text-xs">
                                            Subject line of reminder email sent less than 24 hours in advance
                                        </div>
                                    </div>
                                </template>
                                <input v-model="form.subjectLineTranslations['subject-reminders-lt-24hrs']" class="form-field">
                            </form-field-wrapper>

                            <form-field-wrapper class="w-full md:w-1/2" :error="form.errors.get('subjectLineTranslations.subject-reminders-gte-24hrs')">
                                <template #label>
                                    <div class="flex flex-col space-y-2">
                                        <div class="flex space-x-2">
                                            <span>Email Subject Line</span>
                                            <a
                                                v-if="showManageMultiLanguageSubjectLinesLink"
                                                :href="route('settings.event.languages-and-translations', event)"
                                                class="button-text button-primary normal-case"
                                            >
                                                Manage Multi-language Subject lines
                                            </a>
                                        </div>
                                        <div class="font-normal normal-case text-xs">
                                            Subject line of reminder email sent 24 hours or more in advance
                                        </div>
                                    </div>
                                </template>
                                <input v-model="form.subjectLineTranslations['subject-reminders-gte-24hrs']" class="form-field">
                            </form-field-wrapper>

                            <form-field-wrapper
                                label="Email Introduction"
                                tooltip="Add event details or additional information. Added text will be displayed above the receipt and guest confirmation details."
                                class="w-full md:w-1/2"
                            >
                                <div class="border p-2 min-h-xs" @click.self="$refs.reminderIntroduction.focus()">
                                    <tiptap-editor
                                        ref="reminderIntroduction"
                                        v-model="reminderIntroduction"
                                        simple
                                        :allow-links="allowCustomHyperLinksInEmails"
                                    ></tiptap-editor>
                                </div>
                            </form-field-wrapper>

                            <form-field-wrapper
                                label="Email Conclusion"
                                tooltip="Add event details or additional information. Added text will be displayed below the receipt and guest confirmation details."
                                class="w-full md:w-1/2"
                            >
                                <div class="border p-2 min-h-xs" @click.self="$refs.reminderConclusion.focus()">
                                    <tiptap-editor
                                        ref="reminderConclusion"
                                        v-model="reminderConclusion"
                                        simple
                                        :allow-links="allowCustomHyperLinksInEmails"
                                    ></tiptap-editor>
                                </div>
                            </form-field-wrapper>
                        </div>
                    </template>
                </accordion-list-item>
            </accordion-list>

        </div>

        <div class="flex mt-6 justify-end">
            <button
                v-if="form.isModified()"
                class="button flex-1 md:flex-none mr-4"
                @click="form.restore()"
            >
                Cancel
            </button>

            <stateful-button
                :disabled="!form.isModified()"
                :loading="form.processing"
                class="button-primary flex-1 md:flex-none"
                @click="saveSettings"
            >
                Save
            </stateful-button>
        </div>
    </section>
</template>

<script>
import get from 'lodash/get';
import { sync } from 'vuex-pathify';
import Form from '@/validation/Form';

export default {
    name: 'ConfirmationEmailSettings',

    props: {
        availableFromEmailDomains: {
            type: Array,
            required: true
        }
    },

    data () {
        return {
            copyEventText: '{{event_name}}',
            copyNumberOfGuestsText: '{{number_of_guests}}',
            displayNumberOfGuestsCopiedBadge: false,
            displayEventCopiedBadge: false,
            form: null,
            typeAccordion: []
        };
    },

    computed: {
        allowCustomHyperLinksInEmails () {
            if (this.event.owner.plan.features.AllowCustomHyperLinksInEmails.type === 'always') {
                return true;
            }

            return this.event.owner.is_verified;
        },

        areRemindersEnabled () {
            return this.form.reminder_definitions[0].active || this.form.reminder_definitions[1].active;
        },

        autoRemindersTooltip () {
            return this.eventDoesntHaveDates
                ? this.noDatesTooltip
                : 'RSVPify will automatically send a reminder to guests ahead of your event or guest’s scheduled appointment time. Reminders will include the contents of your confirmation email.';
        },

        customFromNameTooltip () {
            return this.isCustomFromNameDisabled ? `Please upgrade to customize the 'From Name'.` : null;
        },

        disableAutoReminderOptions () {
            if (this.event.settings.recurringTimeSlots.active) {
                return false;
            }

            return !this.event.usesCalendarInvites && !this.event.starts_at;
        },

        emailLogo () {
            if (this.form.settings.confirmationEmails.logoToUse === null) {
                return null;
            }

            if (this.useNewLogo) {
                return this.newLogoUrl;
            }

            return this.formEmailLogo || this.formLogo;
        },

        ...sync('Event/*'),

        eventDoesntHaveDates () {
            if (this.event.settings.recurringTimeSlots.active) {
                return false;
            }

            return !this.event.usesCalendarInvites && !this.event.starts_at;
        },

        ...sync('Form/form@settings', {
            formLogo: 'logo_url',
            formEmailLogo: 'email_logo_url'
        }),

        imageUploadText () {
            return this.newLogoUrl ? 'Replace Logo' : 'Add Logo';
        },

        isCustomFromNameDisabled () {
            if (this.event.owner.plan.features.CustomFromName.type === 'disabled') {
                return true;
            }

            if (this.event.owner.plan.features.CustomFromName.type === 'if-verified') {
                return !this.event.owner.is_verified;
            }

            return false;
        },

        isCustomFromNameHidden () {
            return this.event.owner.plan.features.CustomFromName.type === 'hidden';
        },

        isEventTemplate () {
            return this.event.user_id === null;
        },

        newLogoUrl () {
            return this.form.settings.confirmationEmails.logoUrl || this.event.confirmationEmailLogoUrl;
        },

        noDatesTooltip () {
            if (!this.eventDoesntHaveDates) {
                return null;
            }

            return 'To enable auto-reminders, add the date and time of your event in Setup > Event Settings > Event Details or enable Calendar Invitations on one or more times or sessions in the Form Builder.';
        },

        reminderConclusion: {
            get () {
                return this.form.reminder_definitions[0].settings.conclusion;
            },
            set (newValue) {
                this.form.reminder_definitions[0].settings.conclusion = newValue;
                this.form.reminder_definitions[1].settings.conclusion = newValue;
            }
        },

        reminderIntroduction: {
            get () {
                return this.form.reminder_definitions[0].settings.introduction;
            },
            set (newValue) {
                this.form.reminder_definitions[0].settings.introduction = newValue;
                this.form.reminder_definitions[1].settings.introduction = newValue;
            }
        },

        showManageMultiLanguageSubjectLinesLink () {
            return this.event.enabled_languages.length > 1;
        },

        useNewLogo () {
            return this.form.settings.confirmationEmails.logoToUse === 'new';
        }
    },

    created () {
        const defaultLanguage = this.event.enabled_languages.find((language) => { return language.default; });

        const emailParts = get(this.event.settings, 'confirmationEmails.fromEmail', 'invitations@rsvpify.com').split('@');

        this.form = new Form({
            reminder_definitions: this.event.reminder_definitions,
            settings: this.event.settings,
            fromEmail: emailParts[0],
            fromEmailDomain: emailParts[1],
            subjectLineTranslations: {
                'subject-confirmation-attending': get(defaultLanguage, 'translations.subject-confirmation-attending'),
                'subject-confirmation-not-attending': get(defaultLanguage, 'translations.subject-confirmation-not-attending'),
                'subject-confirmation-canceled': get(defaultLanguage, 'translations.subject-confirmation-canceled'),
                'subject-reminders-gte-24hrs': get(defaultLanguage, 'translations.subject-reminders-gte-24hrs'),
                'subject-reminders-lt-24hrs': get(defaultLanguage, 'translations.subject-reminders-lt-24hrs')
            }
        });
    },

    methods: {
        getReminderOptions (exclude = null) {
            const base = [
                { label: '1 Hour', value: 60 * 60 },
                { label: '3 Hours', value: 3 * 60 * 60 },
                { label: '6 Hours', value: 6 * 60 * 60 },
                { label: '12 Hours', value: 12 * 60 * 60 },
                { label: '24 Hours', value: 24 * 60 * 60 },
                { label: '2 Days', value: 2 * 24 * 60 * 60 },
                { label: '3 Days', value: 3 * 24 * 60 * 60 },
                { label: '7 Days', value: 7 * 24 * 60 * 60 }
            ];

            return base.filter(({ value }) => { return value !== exclude; });
        },

        isReminderDisabled (index) {
            if (this.eventDoesntHaveDates) {
                return true;
            }

            return this.form.reminder_definitions[index].hasBeenSent;
        },

        resolveReminderTooltip (index) {
            if (this.eventDoesntHaveDates) {
                return null;
            }

            if (!this.form.reminder_definitions[index].hasBeenSent) {
                return null;
            }

            return 'This auto-reminder has already sent and cannot be updated. You can schedule a second auto-reminder to all attendees, or choose to send a manual reminder via the Email tab to select audiences any time.';
        },

        saveSettings () {
            this.$set(this.form.settings.confirmationEmails, 'fromEmail', `${this.form.fromEmail.trim()}@${this.form.fromEmailDomain}`);

            const action = this.isEventTemplate
                ? this.form.post(this.route('api.admin.event-templates.update', this.event), null, this.event)
                : this.form.patch(this.route('api.events.update', this.event));

            action.then(() => {
                this.$toasted.global.success({
                    message: 'Email confirmation settings have been updated.',
                    onComplete: () => {
                        window.location.reload();
                    }
                });
            }).catch(() => {
                this.$toasted.global.error('There was a problem updating the email confirmation settings.');
            });
        }
    }
};
</script>
