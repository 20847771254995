import cloneDeep from 'lodash/cloneDeep';
import { find } from 'lodash';

const tagPlaceholders = {
    '[guest_name]': 'John Smith',
    '[confirmation_code]': 'XWNIXGBL',
    '[guest_tags]': '[Guest Tags]'
};

/**
 * Converts mm to pixels based on DPI
 * @param {number} mm - Millimeters to convert
 * @param {number} dpi - Dots per inch
 * @returns {number} - Pixels
 */
function mmToPx (mm, dpi) {
    return Math.round(mm * dpi / 25.4);
}

/**
 * Converts pixels to mm based on DPI
 * @param {number} px - Pixels to convert
 * @param {number} dpi - Dots per inch
 * @returns {string} - Millimeters with 2 decimal places
 */
function pxToMm (px, dpi) {
    return (px * 25.4 / dpi).toFixed(2);
}

/**
 * Resolves template tags based on submission data
 * @param {string} tag - The tag to resolve
 * @param {Object} submission - Submission data
 * @param {number} guestId - Guest ID to find in the submission
 * @returns {string} - Resolved tag content
 */
function resolveTagFromSubmission (tag, submission, guestId = null) {
    if (!submission) {
        return tagPlaceholders[tag];
    }

    const guest = guestId
        ? find(submission.guests, { id: guestId })
        : submission.guests[0];

    if (tag === '[confirmation_code]') {
        return submission.confirmationCode;
    }

    if (tag === '[guest_name]') {
        return guest.formalName;
    }

    if (tag === '[guest_tags]') {
        return guest.tags.map((guestTag) => { return guestTag.name; }).join(', ');
    }

    if (tag === '[qr_code]') {
        return window.route('submissions.qrcode', { submission: submission.uuid });
    }

    return '';
}

/**
 * Resolves template tags based on guest data
 * @param {string} tag - The tag to resolve
 * @param {Object} guest - Guest data
 * @returns {string} - Resolved tag content
 */
export function resolveTagFromGuestResource (tag, guest) {
    if (tag === '[guest_name]') {
        return guest.formalName;
    }

    if (tag === '[guest_tags]') {
        return guest.tags;
    }

    if (tag === '[confirmation_code]') {
        return guest.confirmationCode;
    }

    if (tag === '[qr_code]') {
        return window.route('submissions.qrcode', { submission: guest.submissionUuid });
    }

    return '';
}

export function WarnIfBadgeTemplateIsEmpty (template, $toasted) {
    if (template.elements.length === 0) {
        $toasted.show('The currently selected badge template is empty. Please go to Settings > Badging to edit your badge templates.', {
            type: 'error',
            duration: 5000
        });

        return true;
    }

    return false;
}

/**
 * Renders a badge template to HTML
 * @param {Object} template - The template object containing elements
 * @param {Object} event - Event data
 * @param {Object} [submission] - Optional submission (or guest) data
 * @param {Object} [options] - Optional rendering options
 * @param {number} [options.dpi=300] - DPI for rendering
 * @param {number} [options.pageWidthMm=82] - Page width in millimeters
 * @param {number} [options.pageHeightMm=62] - Page height in millimeters
 * @param {number} [options.guestId=null] - If null, fetch first guest from submission
 * @param {Function} [options.tagResolver=resolveTagFromSubmission] - Tag resolver function
 * @returns {string} - Generated HTML
 */
function RenderBadgeTemplate (template, event, submission = null, options = {}) {
    const {
        dpi = 300,
        pageWidthMm = 82,
        pageHeightMm = 62,
        tagResolver = resolveTagFromSubmission,
        guestId = null
    } = options;

    const pageWidthPx = mmToPx(pageWidthMm, dpi);
    const pageHeightPx = mmToPx(pageHeightMm, dpi);

    const scaleX = pageHeightPx / 310;
    const scaleY = pageWidthPx / 410;

    const convertedElements = template.elements.map((templateElement) => {
        const element = cloneDeep(templateElement);
        let fontSize = 16;

        const matches = element.content && element.content.match(/font-size: (\d*?)px/);

        if (matches) {
            fontSize = parseInt(matches[1], 10);
            element.content = element.content.replace(/font-size: (\d*?)px/, '');
        }

        if (element.tag) {
            if (element.type === 'text') {
                element.content = element.content.replace(
                    tagPlaceholders[element.tag],
                    tagResolver(element.tag, submission, guestId)
                );
            } else if (element.type === 'image') {
                const qrCodeSrc = tagResolver(element.tag, submission, guestId);

                if (qrCodeSrc) {
                    element.src = tagResolver(element.tag, submission, guestId);
                }
            }
        }

        const additionalStyles = {};

        if (['center', 'right'].includes(element.alignment)) {
            Object.assign(additionalStyles, {
                left: '0mm',
                width: '100%',
                'text-align': element.alignment
            });
        }

        return {
            ...element,
            content: element.content,
            style: {
                position: 'absolute',
                left: `${pxToMm(element.x * scaleX, dpi)}mm`,
                top: `${pxToMm(element.y * scaleY, dpi)}mm`,
                'white-space': 'nowrap',
                'font-size': `${pxToMm(fontSize * scaleY, dpi)}mm`,
                ...additionalStyles
            }
        };
    });

    // Generate the HTML
    const containerStyle = `
        width: ${pageWidthPx}px;
        height: ${pageHeightPx}px;
        position: relative;
    `;

    const badgeStyle = `
        width: 100%;
        height: 100%;
        font-family: Arial, sans-serif;
        border: 1px solid black;
    `;

    const elementsHtml = convertedElements.map((element) => {
        const styleString = Object.entries(element.style)
            .map(([key, value]) => { return `${key}: ${value}`; })
            .join('; ');

        if (element.type === 'image') {
            return `<img src="${element.src}" style="${styleString}; width: 75px; height: 75px;">`;
        }

        return `<div style="${styleString}">${element.content}</div>`;
    }).join('\n');

    return `
            <div class="badgeTemplate" style="${badgeStyle}">
                ${elementsHtml}
            </div>
    `;
}

export default RenderBadgeTemplate;
