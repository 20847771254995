<script>
import InteractsWithFeatureFlags from '@/mixins/InteractsWithFeatureFlags';

export default {
    name: 'FeatureEnabled',

    mixins: [InteractsWithFeatureFlags],

    props: {
        feature: {
            type: String,
            required: true
        },
        passthrough: {
            type: Boolean,
            default: false
        }
    },

    created () {
        // Loading feature flags explicitly like this is not required when
        // using the "InteractsWithFeatureFlags" mixin. The mixin is smart
        // enough to load them automatically when they're needed. We're doing
        // it explicitly to have the ability to show the "loading" slot before
        // we know for sure the status of a feature flag.
        this.loadFeatureFlags();
    },

    render () {
        if (this.passthrough || (this.areFeatureFlagsLoaded && this.isFeatureEnabled(this.feature))) {
            if (this.$scopedSlots?.default) {
                return this.$scopedSlots.default();
            }
        }

        return null;
    }
};
</script>
